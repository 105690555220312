(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('DiscountController', DiscountController);

    DiscountController.$inject = ['$state', 'OfferDiscountService', '$scope', '$rootScope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', 'DateUtils', '$compile', '$translate', '$mdDialog'];

    function DiscountController($state, OfferDiscountService, $scope, $rootScope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, DateUtils, $compile, $translate, $mdDialog) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};
        vm.total = 0;

        vm.title = $translate.instant('vipWebApp.discount.home.title');
        vm.newButtonTitle = $translate.instant('vipWebApp.discount.home.new');

        var discountId = 1;

        vm.delete = deleteDiscount;

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('shortDescription',$translate('vipWebApp.discount.title')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('dateStart',$translate('vipWebApp.discount.startPublish')).notSortable().withOption("name", "dateStart").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm");
            }),
            DTColumnBuilder.newColumn('dateEnd',$translate('vipWebApp.discount.endPublish')).notSortable().withOption("name", "dateEnd").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm");
            }),
            DTColumnBuilder.newColumn('cardName',$translate('vipWebApp.discount.cardName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('enabled',$translate('vipWebApp.discount.enabled')).notSortable().withOption('defaultContent', '').withOption("width", "72px").renderWith(enabledHtml),
            DTColumnBuilder.newColumn('hasImage',$translate('vipWebApp.discount.hasImage')).notSortable().withOption('defaultContent', '').withOption("width", "72px").renderWith(enabledHtml),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '250px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/discounts/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[1,'desc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');


        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function enabledHtml(data, type, full, meta){
            return (data) ? '<i class="icon-checkbox-marked-circle green-500-fg"></i>' : '<i class="icon-cancel red-500-fg"></i>';
        }

        function actionsHtml(data, type, full, meta){
            var html = '<a ui-sref="app.businesses.discounts.edit({id: ' + data.id + '})" >' +
                    '<button class="md-button edit-button md-icon-button" aria-label="Business details" translate translate-attr-aria-label="BP.BUSINESS_PUSH">' +
                    '<md-tooltip md-direction="bottom"><span data-translate="entity.action.edit">EDIT</span></md-tooltip>' +
                    '<i class="md-pinkTheme-theme md-font material-icons icon-pencil amber-600-fg"></i>' +
                    '</button>' +
                '</a>' +
                '<a ui-sref="app.businesses.discounts.orders({id: ' + data.id + '})" >' +
                    '<button class="md-button edit-button md-icon-button" aria-label="Business details" translate translate-attr-aria-label="BP.BUSINESS_PUSH">' +
                        '<md-tooltip md-direction="bottom"><span data-translate="vipWebApp.offer.consumes">CONSUMOS</span></md-tooltip>' +
                        '<i class="md-pinkTheme-theme md-font material-icons icon-barcode blue-600-fg"></i>' +
                    '</button>' +
                '</a>' +
                '<a ui-sref="app.businesses.discounts.subscriptions({id: ' + data.id + '})" >' +
                '<button class="md-button edit-button md-icon-button" aria-label="Business details" >' +
                '<md-tooltip md-direction="bottom"><span data-translate="vipWebApp.offer.subscription">LISTA</span></md-tooltip>' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-clipboard blue-300-fg"></i>' +
                '</button>' +
                '</a>' +
                '<a ng-click="vm.delete($event, ' + data.id + ')" >' +
                '<button class="md-button edit-button md-icon-button" aria-label="Business details" translate translate-attr-aria-label="BP.BUSINESS_PUSH">' +
                '<md-tooltip md-direction="bottom"><span data-translate="entity.action.delete">DELETE</span></md-tooltip>' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-trash red-500-fg"></i>' +
                '</button>' +
                '</a>';
            return html;
        }

        function deleteDiscount(ev, id, name) {

            var confirm = $mdDialog.confirm()
                .title($translate.instant('global.delete.confirmation.title'))
                .ariaLabel('Delete Category')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                OfferDiscountService.deleteDiscount(id).then(function(result) {
                    vm.dtInstance.reloadData();
                    $rootScope.$broadcast("menuChanged");
                });
            }, function () {

            });

        }

    }
})();
