(function ()
{
    'use strict';

    stateConfig.$inject = ["$stateProvider", "$translatePartialLoaderProvider", "msApiProvider", "msNavigationServiceProvider"];
    angular
        .module('vipWebApp')
        .config(stateConfig);

    /** @ngInject */
    function stateConfig($stateProvider, $translatePartialLoaderProvider, msApiProvider, msNavigationServiceProvider)
    {
        $stateProvider
            .state('customers', {
                parent: 'app',
                url: '/customers',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR'],
                    pageTitle: 'vipWebApp.customer.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/main/customers/customer.html',
                        controller: 'CustomerController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('customers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });

        msNavigationServiceProvider.saveItem('apps.customers', {
            icon  : 'icon-account-multiple',
            title: 'global.menu.vc.customers',
            state: 'customers',
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR'],
            weight: 1
        });

    }
})();
