(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('CountryDialogController', CountryDialogController);

    CountryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Country'];

    function CountryDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Country) {
        var vm = this;

        vm.country = entity;
        vm.clear = clear;
        vm.save = save;

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.country.id !== null) {
                Country.update(vm.country, onSaveSuccess, onSaveError);
            } else {
                Country.save(vm.country, onSaveSuccess, onSaveError);
            }
        }

    }
})();
