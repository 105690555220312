(function ()
{
    'use strict';

    OrderController.$inject = ['$state', 'order', 'OrderStatuses', 'Order'];

    angular
        .module('app.e-commerce')
        .controller('OrderController', OrderController);

    /** @ngInject */
    function OrderController($state, order, OrderStatuses, Order)
    {
        var vm = this;

        // Data
        vm.order = order;
        vm.orderStatuses = OrderStatuses;
        vm.actions = [];

        angular.forEach(vm.orderStatuses, function(data) {
            angular.forEach(vm.order.statuses, function(status) {
                if (status.status==data.id) {
                    status.name = data.name;
                    status.color = data.color;
                }
            });
            if (data.id==vm.order.orderStatus) {
                updateStatusActions(data.next);
            }
        });

        vm.dtInstance = {};
        vm.dtOptions = {
            dom       : 'rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>',
            columnDefs: [
                {
                    // Target the id column
                    targets: 0,
                    width  : '72px'
                },
                {
                    // Target the image column
                    targets   : 1,
                    filterable: false,
                    sortable  : false,
                    width     : '80px'
                },
                {
                    // Target the actions column
                    targets           : 5,
                    responsivePriority: 1,
                    filterable        : false,
                    sortable          : false
                }
            ],
            pagingType: 'simple',
            lengthMenu: [10, 20, 30, 50, 100],
            pageLength: 20,
            responsive: true
        };

        vm.newStatus = '';

        // Methods
        vm.gotoOrders = gotoOrders;
        vm.gotoProductDetail = gotoProductDetail;
        vm.updateStatus = updateStatus;

        //////////

        /**
         * Go to orders page
         */
        function gotoOrders()
        {
            $state.go('app.e-commerce.orders');
        }

        /**
         * Go to product page
         * @param id
         */
        function gotoProductDetail(id)
        {
            $state.go('app.e-commerce.products.detail', {id: id});
        }

        /**
         * Update order status
         *
         * @param id
         */
        function updateStatus(status)
        {
            if ( !status )
            {
                return;
            }

            // Llamada al servicio REST para añadir el estado
            updateStatusActions(status.next);
        }

        function updateStatusActions(next) {
            vm.actions = [];
            angular.forEach(next, function(nextAction) {
                angular.forEach(vm.orderStatuses, function(action) {
                    if (nextAction==action.id) {
                        vm.actions.push(action);
                    }
                });
            })
        }
    }
})();
