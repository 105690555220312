(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('ReadQRController', ReadQRController);

    ReadQRController.$inject = ['$state', '$scope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', 'DateUtils', '$translate', '$interval', 'ReadQRService'];

    function ReadQRController($state, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, DateUtils, $translate, $interval, ReadQRService) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};
        vm.total = 0;

        vm.dateStart = new Date();
        vm.dateEnd = new Date();
        vm.title = $translate.instant('vipWebApp.readQR.home.title');

        vm.exportData = exportData;

        vm.interval = $interval(getNewReads, 60000);

        vm.dtColumns = [
            DTColumnBuilder.newColumn('fullName',$translate('vipWebApp.readQR.fullName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('cardName',$translate('vipWebApp.readQR.cardName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('readerName',$translate('vipWebApp.readQR.userName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('dateCreated',$translate('vipWebApp.readQR.dateCreated')).notSortable().withOption("name", "dateCreated").withOption('defaultContent', '').renderWith(function(data, type, full) {
                var result = $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm");
                if (full.comment && full.comment!=='') result = result + "  <i class='fa blue-600-fg icon-alert-circle' title='" + full.comment + "'></i>";
                return result;
            }),
            DTColumnBuilder.newColumn('totalMan',$translate('vipWebApp.readQR.totalMan')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('totalWoman',$translate('vipWebApp.readQR.totalWoman')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('total',$translate('vipWebApp.readQR.totalMen')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('totalExpense',$translate('vipWebApp.readQR.totalExpense')).notSortable().withOption('defaultContent', '')
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/readQR/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                    vm.total=0;vm.totalExpense=0;
                    angular.forEach(jqXHR.responseJSON.data, function(d) {
                        vm.total+=d.total;vm.totalExpense+= d.totalExpense;
                    })
                }
            })
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');


        function getNewReads() {
            var minDate  = new Date(new Date() - 1);
            if (minDate < vm.dateStart) {
                vm.dtInstance.reloadData();
            }
        }

        $scope.$watch("vm.dateStart", function(){
            if (vm.dtInstance.dataTable) {
                vm.dtInstance.DataTable.search(DateUtils.convertLocalDateTimeToServer(vm.dateStart));
                vm.dtInstance.reloadData();
                vm.searched=true;
            }
        });

        $scope.$on('$destroy',function() {
            $interval.cancel(vm.interval);
        });

        function exportData() {
            ReadQRService.exportToExcel(DateUtils.convertLocalDateToServer(vm.dateStart)).then(function(response){
                var header = response.headers('Content-Disposition')
                var fileName = header.split("=")[2].replace(/\"/gi,'');
                console.log(fileName);

                var blob = new Blob([response.data], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                var link = angular.element('<a/>');
                link.attr({
                    href : objectUrl,
                    download : fileName
                })[0].click();
            });
        }
    }
})();
