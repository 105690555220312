(function() {
    'use strict';

    angular
        .module('vipWebApp', [
            'ngStorage',
            'tmh.dynamicLocale',
            'pascalprecht.translate',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',

            // Common 3rd Party Dependencies
            'datatables',
            'uiGmapgoogle-maps',
            'textAngular',
            'xeditable',

            // Fuse theme
            'app.core',
            'app.navigation',
            'app.toolbar',

            // Vipcard Modules
            'app.businesses',
            'app.e-commerce',
            'app.contacts'
        ])
        .run(run);

    run.$inject = ['stateHandler', 'translationHandler', '$rootScope', '$timeout', '$state'];

    function run(stateHandler, translationHandler, $rootScope, $timeout, $state) {
        stateHandler.initialize();
        translationHandler.initialize();

        // Activate loading indicator
        var stateChangeStartEvent = $rootScope.$on('$stateChangeStart', function ()
        {
            $rootScope.loadingProgress = true;
        });

        // De-activate loading indicator
        var stateChangeSuccessEvent = $rootScope.$on('$stateChangeSuccess', function ()
        {
            $timeout(function ()
            {
                $rootScope.loadingProgress = false;
            });
        });

        // Store state in the root scope for easy access
        $rootScope.state = $state;

        // Cleanup
        $rootScope.$on('$destroy', function ()
        {
            stateChangeStartEvent();
            stateChangeSuccessEvent();
        });

    }
})();
