(function ()
{
    'use strict';

    stateConfig.$inject = ["$stateProvider", "$translatePartialLoaderProvider", "msApiProvider", "msNavigationServiceProvider"];
    angular
        .module('vipWebApp')
        .config(stateConfig);

    /** @ngInject */
    function stateConfig($stateProvider, $translatePartialLoaderProvider, msApiProvider, msNavigationServiceProvider)
    {
        $stateProvider
            .state('businessesRequest', {
                parent: 'app',
                url: '/businessesRequest',
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'vipWebApp.businessRequest.home.title'
                },
                views: {
                    'content@app': {
                        templateUrl: 'app/main/request/request.html',
                        controller: 'BusinessRequestController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                        $translatePartialLoader.addPart('businessRequest');
                        $translatePartialLoader.addPart('customers');
                        $translatePartialLoader.addPart('global');
                        return $translate.refresh();
                    }]
                }
            });

        msNavigationServiceProvider.saveItem('apps.businessesRequest', {
            icon  : 'icon-table',
            title: 'global.menu.vc.businessesRequest',
            state: 'businessesRequest',
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
            weight: 15
        });

    }
})();
