(function() {
    'use strict';
    angular
        .module('vipWebApp')
        .factory('Application', Application)
        .factory('ApplicationService', ApplicationService);

    Application.$inject = ['$resource', 'DateUtils'];
    ApplicationService.$inject = ['$http'];

    function Application ($resource, DateUtils) {
        var resourceUrl =  'api/applications/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.creationDate = DateUtils.convertDateTimeFromServer(data.creationDate);
                        data.deletionDate = DateUtils.convertDateTimeFromServer(data.deletionDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function ApplicationService ($http) {
        var service = {
            getAllLinks: getAllLinks
        };

        return service;

        function getAllLinks() {
            return $http.get('api/applications/business/links').then(function (response) {
                return response.data;
            });
        }
    }

})();
