(function ()
{
    'use strict';

    ContactsController.$inject = ["$scope", "$stateParams", "$mdSidenav", "Contacts", "User", "Offers", "SelectedBusiness", "msUtils", "$mdDialog", "$document", "UserAppService", "$filter", "Upload"];

    angular
        .module('app.contacts')
        .controller('ContactsController', ContactsController);

    /** @ngInject */
    function ContactsController($scope, $stateParams, $mdSidenav, Contacts, User, Offers, SelectedBusiness, msUtils, $mdDialog, $document, UserAppService, $filter, Upload)
    {

        var vm = this;
        vm.contacts = Contacts;
        vm.offers = Offers;
        vm.user = User;
        vm.filterIds = null;
        vm.listType = 'all';
        vm.listOrder = 'name';
        vm.listOrderAsc = false;
        vm.selectedContacts = [];
        vm.whiteSpace = whiteSpace;
        vm.newGroupName = '';

        // Methods
        vm.filterChange = filterChange;

        vm.toggleSelectContact = toggleSelectContact;
        vm.deselectContacts = deselectContacts;
        vm.selectAllContacts = selectAllContacts;
        vm.sendPush = sendPush;
        vm.sendPushAll = sendPushAll;
        vm.sendPushPending = sendPushPending;
        vm.addNewGroup = addNewGroup;
        vm.deleteGroup = deleteGroup;
        vm.toggleSidenav = toggleSidenav;
        vm.toggleInArray = toggleInArray;
        vm.toggleInGroupArray = toggleInGroupArray;
        vm.exists = msUtils.exists;
        vm.placeId = $stateParams.id;

        /**
         * Change Contacts List Filter
         * @param type
         */
        function filterChange(type)
        {

            vm.listType = type;

            if ( type === 'all' )
            {
                vm.filterIds = null;
            }
            else if ( type === 'frequent' )
            {
                vm.filterIds = vm.user.frequentContacts;
            }
            else if ( type === 'starred' )
            {
                vm.filterIds = vm.user.starred;
            }
            else if ( type === 'zipcode' )
            {
                alert("demanar zip");
                //vm.filterIds = vm.user.zipcode;
            }
            else if ( angular.isObject(type) )
            {
                vm.filterIds = type.contactIds;
            }

            vm.selectedContacts = [];

        }

        function toggleInGroupArray(group) {
            angular.forEach(vm.selectedContacts, function(contact) {
                toggleInArray(contact.id, group.contactIds, group.id)
            });

        }

        function toggleInArray(item, array, groupId)
        {
            if (array.indexOf(item) === -1 )
            {
                array.push(item);
                UserAppService.addUserToGroup(vm.placeId, groupId, item);
            }
            else
            {
                array.splice(array.indexOf(item), 1);
                UserAppService.removeUserFromGroup(vm.placeId, groupId, item);
            }
        }

        function whiteSpace(name, num) {
            var result = "";
            var n = name.length;
            while (n < num) { result += " "; n++; }
            return result;
        }

        /**
         * Send push message
         */
        function sendPush(all, ev)
        {
            var title = "Enviar mensaje al cliente seleccionado";
            if (all) {
                title = "Enviar mensaje a todos los clientes"
            } else if (vm.selectedContacts.length>1) {
                title = "Enviar mensaje a los clientes seleccionados"
            }
            showDialog(all, null, ev, title);
        }
        function sendPushAll(ev)
        {
            var title = "Enviar mensaje a todos los clientes incluidos los dados de baja";
            showDialog(true, "deleted", ev, title);
        }
        function sendPushPending(ev)
        {
            var title = "Enviar mensaje a los clientes con Vips pendiente de activar";
            showDialog(true, "pending", ev, title);
        }


        function showDialog(all, type, ev, title) {
            $mdDialog.show({
                controller: DialogController,
                templateUrl: '/app/main/contacts/dialogs/push-dialog.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                scope: $scope.$new(),
                locals: {
                    all: all,
                    type: type,
                    titleDialog: title
                }
            }).then(function(result) {

            }, function() {

            });
        }

        DialogController.$inject = ["$scope", "$mdDialog", "UserAppService", "all", "type", "titleDialog"];
        function DialogController($scope, $mdDialog, UserAppService, all, type, titleDialog) {

            $scope.titleDialog = titleDialog;
            $scope.all = all;
            $scope.type = type;
            $scope.advanced = false;
            $scope.imageError = false;
            $scope.pushLinkUrl = "";
            $scope.pushImageUrl = "";
            $scope.mediaFile = "";
            $scope.errorFile = "";

            $scope.hide = function() {
                $mdDialog.hide();
            };

            $scope.cancel = function() {
                $mdDialog.cancel();
            };

            $scope.toggleAdvanced = function() {
                $scope.advanced = !$scope.advanced;
            };

            $scope.prepareImage = function(file) {
                $scope.file = file;
            };

            $scope.answer = function(answer) {
                if (answer==="ok") {
                    var data = {users: [], message: $scope.messageToPush, link: null, imageUrl: null, bp: null};
                    var validateImage = false;

                    // Envio mensaje Push al usuarios
                    if ($scope.all) {
                        if ((vm.search !== undefined && vm.search !== '') || (vm.filterIds !== null && vm.filterIds.length > 0)) {
                            var contacts = $filter("filter")(vm.contacts, vm.search);
                            contacts = $filter("filterByIds")(contacts, vm.filterIds);
                            angular.forEach(contacts, function (contact) {
                                data.users.push(contact.id);
                            });
                        } else {
                            data.bp = vm.placeId;
                            data.type = $scope.type;
                        }
                    } else {
                        angular.forEach(vm.selectedContacts, function (contact) {
                            data.users.push(contact.id);
                        });
                    }

                    if ($scope.advanced) {
                        data.link = $scope.pushLinkUrl;
                        data.imageUrl = $scope.pushImageUrl;
                        data.mediaFile = $scope.mediaFile;
                        data.offerQR = $scope.offerId;

                        // Revisamos que si tenemos una imagen esta sea válida
                        if (data.imageUrl.trim()!=="") {
                            validateImage=true;
                            testImage(data.imageUrl, function(url, result) {
                                if (result==="success") {
                                    UserAppService.pushMessage(vm.placeId, data);
                                    $mdDialog.hide(answer);
                                } else {
                                    $scope.imageError = true;
                                }
                            });
                        } else if (data.mediaFile && data.mediaFile.name && data.mediaFile.name.trim()!=="") {
                            validateImage=true;
                            Upload.upload({
                                url: 'api/user-apps/business/' + vm.placeId + '/pushMedia',
                                data: { file: $scope.file, 'id': vm.placeId }
                            }).progress(function (evt) {
                                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                console.log('progress: ' + progressPercentage + '% ');
                            }).success(function (result, status, headers, config) {
                                data.imageUrl = result.value;
                                UserAppService.pushMessage(vm.placeId, data);
                                $mdDialog.hide(answer);
                            });
                        }
                    }

                    if (validateImage===false) {
                        UserAppService.pushMessage(vm.placeId, data);
                        $mdDialog.hide(answer);
                    }
                } else {
                    $mdDialog.hide(answer);
                }
            };

            function testImage(url, callback, timeout) {
                timeout = timeout || 5000;
                var timedOut = false, timer;
                var img = new Image();
                img.onerror = img.onabort = function() {
                    if (!timedOut) {
                        clearTimeout(timer);
                        callback(url, "error");
                    }
                };
                img.onload = function() {
                    if (!timedOut) {
                        clearTimeout(timer);
                        callback(url, "success");
                    }
                };
                img.src = url;
                timer = setTimeout(function() {
                    timedOut = true;
                    // reset .src to invalid URL so it stops previous
                    // loading, but doesn't trigger new load
                    img.src = "//!!!!/test.jpg";
                    callback(url, "timeout");
                }, timeout);
            }
        }



        /**
         * Toggle selected status of the contact
         *
         * @param contact
         * @param event
         */
        function toggleSelectContact(contact, event)
        {
            if ( event )
            {
                event.stopPropagation();
            }

            if ( vm.selectedContacts.indexOf(contact) > -1 )
            {
                vm.selectedContacts.splice(vm.selectedContacts.indexOf(contact), 1);
            }
            else
            {
                vm.selectedContacts.push(contact);
            }
        }

        /**
         * Deselect contacts
         */
        function deselectContacts()
        {
            vm.selectedContacts = [];
        }

        /**
         * Sselect all contacts
         */
        function selectAllContacts()
        {
            vm.selectedContacts = $scope.filteredContacts;
        }

        /**
         *
         */
        function addNewGroup()
        {
            if ( vm.newGroupName === '' )
            {
                return;
            }

            UserAppService.createGroup(vm.placeId, vm.newGroupName).then(function(data) {
                var newGroup = {
                    'id'        : data.id,
                    'name'      : vm.newGroupName,
                    'contactIds': []
                };

                if (vm.user.groups==undefined) vm.user.groups = [];
                vm.user.groups.push(newGroup);
                vm.newGroupName = '';
            });

        }

        /**
         * Delete Group
         */
        function deleteGroup(ev)
        {
            var group = vm.listType;

            var confirm = $mdDialog.confirm()
                .title('Are you sure want to delete the group?')
                .htmlContent('<b>' + group.name + '</b>' + ' will be deleted.')
                .ariaLabel('delete group')
                .targetEvent(ev)
                .ok('OK')
                .cancel('CANCEL');

            $mdDialog.show(confirm).then(function ()
            {

                vm.user.groups.splice(vm.user.groups.indexOf(group), 1);
                filterChange('all');
                UserAppService.deleteGroup(vm.placeId, group.id);

            });

        }

        /**
         * Toggle sidenav
         *
         * @param sidenavId
         */
        function toggleSidenav(sidenavId)
        {
            $mdSidenav(sidenavId).toggle();
        }

    }

})();
