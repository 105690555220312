(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('UserManagementDialogController',UserManagementDialogController);

    UserManagementDialogController.$inject = ['$stateParams', 'entity', 'User', 'JhiLanguageService', '$mdDialog'];

    function UserManagementDialogController ($stateParams, entity, User, JhiLanguageService, $mdDialog) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPER_ADMIN', 'ROLE_BUSINESS', 'ROLE_BUSINESS_ADMIN', 'ROLE_BUSINESS_VISITS', 'ROLE_PROMOTOR', 'ROLE_QR_READER', 'ROLE_QR_CHECK', 'ROLE_QR_DAR_VIP',  'ROLE_QR_BONUS', 'ROLE_QR_BAR', 'ROLE_BUSINESS_REQUEST'];
        vm.clear = clear;
        vm.languages = null;
        vm.save = save;
        vm.user = entity;


        JhiLanguageService.getAll().then(function (languages) {
            vm.languages = languages;
        });

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.user.id !== null) {
                User.update(vm.user, onSaveSuccess, onSaveError);
            } else {
                User.save(vm.user, onSaveSuccess, onSaveError);
            }
        }


    }
})();
