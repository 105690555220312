(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('BusinessRequestController', BusinessRequestController)
        .controller('PicDialogController', PicDialogController);

    BusinessRequestController.$inject = ['$rootScope', '$scope', '$filter', 'BusinessRequestService', 'SelectedBusiness', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', 'DateUtils', '$compile', '$translate', '$mdDialog', '$document'];
    function BusinessRequestController($rootScope, $scope, $filter, BusinessRequestService, SelectedBusiness, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, DateUtils, $compile, $translate, $mdDialog, $document) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};


        vm.acceptRequest = acceptRequest;
        vm.denyRequest = denyRequest;
        vm.openUser = openUser;

        vm.title = $translate.instant('vipWebApp.businessRequest.home.title');

        vm.dtColumns = [
            DTColumnBuilder.newColumn('userName',$translate('vipWebApp.businessRequest.dt.user')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('imageUrl',"").withOption("name", "imageUrl").withOption('width', '5%').notSortable().renderWith(function(data, type, full) {
                if (data) return "<img ng-click='vm.openUser($event, \"" + data + "\")' style='border-radius:50%;height:40px;width:40px;' src='" + data + "' />";
                else return "<img style='border-radius:50%;height:40px;width:40px;' src='https://static.vipcard.group/profile/no_avatar.jpg' />";
            }),
            DTColumnBuilder.newColumn('cardName',$translate('vipWebApp.businessRequest.dt.card')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('dateCreated',$translate('vipWebApp.businessRequest.dt.dateCreated')).notSortable().withOption("name", "dateCreated").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy");
            }),
            DTColumnBuilder.newColumn('yearsOld',$translate('vipWebApp.customer.yearsOld')).notSortable().withOption("defaultContent", ""),
            DTColumnBuilder.newColumn('gender',$translate('vipWebApp.customer.gender')).withOption("name", "gender").notSortable().withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter('translate')('vipWebApp.customer.genders.' + data)
            }),
            DTColumnBuilder.newColumn('postalCode',$translate('vipWebApp.customer.postalCode')).withOption("name", "postalCode").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('country',$translate('vipWebApp.customer.country')).withOption("name", "country").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('memberNameReferer',$translate('vipWebApp.customer.referer')).withOption("name", "memberNameReferer").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', getAjaxForm())
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function getAjaxForm() {
            return {
                url: "/api/business-place-requests/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            };
        }

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function actionsHtml(data, type, full, meta){

            var strButtons = '<button ng-click="vm.acceptRequest($event, ' + data.id + ', '+ data.placeId + ')" class="md-button edit-button md-icon-button">' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-account-check green-700-fg" translate translate-attr-aria-label="BP.BUSINESS_PUSH"></i></button>';

            strButtons += '<button ng-click="vm.denyRequest($event, ' + data.id + ', '+ data.placeId + ')" class="md-button edit-button md-icon-button">' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-account-remove red-500-fg" translate translate-attr-aria-label="BP.BUSINESS_PUSH"></i></button>';


            return strButtons;
        }

        /**
         * Open new contact dialog
         *
         * @param ev
         * @param entity
         */
        function acceptRequest(ev, id, placeId)
        {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.businessRequest.confirmation.title'))
                .htmlContent($translate.instant('vipWebApp.businessRequest.confirmation.content'))
                .ariaLabel('Approve request')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            var request = false;
            $mdDialog.show(confirm).then(function () {
                request = true;
                BusinessRequestService.accept(id, placeId).then(function(){
                    vm.dtInstance.reloadData();
                    $rootScope.$broadcast("menuChanged");
                });
            }, function () {

            });
        }

        /**
         * Open new dialog to create entity
         *
         */
        function denyRequest(ev, id, placeId)
        {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.businessRequest.deny.title'))
                .htmlContent($translate.instant('vipWebApp.businessRequest.deny.content'))
                .ariaLabel('Deny request')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                BusinessRequestService.deny(id, placeId).then(function(){
                    vm.dtInstance.reloadData();
                    $rootScope.$broadcast("menuChanged");
                })
            }, function () {

            });
        }

        function openUser(ev, url) {
            $mdDialog.show({
                controllerAs       : 'vm',
                templateUrl        : 'app/main/request/pic-dialog.html',
                parent             : angular.element($document.find('#content-container')),
                targetEvent        : ev,
                clickOutsideToClose: true,
                locals             : {
                    url: url
                },
                controller: PicDialogController
            });
        }
    }

    PicDialogController.$inject = ['$scope', '$mdDialog', 'url'];
    function PicDialogController($scope, $mdDialog, url) {
        $scope.url = url;
    }
})();
