(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('CurrencyDialogController', CurrencyDialogController);

    CurrencyDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Currency'];

    function CurrencyDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Currency) {
        var vm = this;

        vm.currency = entity;
        vm.clear = clear;
        vm.save = save;

        vm.formats = [{ id: "EUROPE_STYLE", value: "0.000,00X -> 1.456,50€"}, { id: "USA_STYLE", value: "X0,000.00 -> $1,445.50"}, { id: "ALT_STYLE", value: "0,000.00X -> 1,541.45€"}];

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.currency.id !== null) {
                Currency.update(vm.currency, onSaveSuccess, onSaveError);
            } else {
                Currency.save(vm.currency, onSaveSuccess, onSaveError);
            }
        }

    }
})();
