(function() {
    'use strict';

    var jhiAlert = {
    template:       '<div ng-repeat="alert in $ctrl.alerts" layout-fill id="toast-container">' +
                        '<md-toast >' +
                             '<div class="md-toast-content"><pre ng-bind-html="alert.msg"></pre></div>' +
                        '</md-toast>' +
                    '</div>',

        controller: jhiAlertController
    };

    angular
        .module('vipWebApp')
        .component('jhiAlert', jhiAlert);

    jhiAlertController.$inject = ['$scope', 'AlertService'];

    function jhiAlertController($scope, AlertService) {
        var vm = this;

        vm.alerts = AlertService.get();
        $scope.$on('$destroy', function () {
            vm.alerts = [];
        });
    }
})();
