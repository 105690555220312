(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'msNavigationServiceProvider'];

    function stateConfig($stateProvider, msNavigationServiceProvider) {
        $stateProvider.state('entity', {
            abstract: true,
            parent: 'app'
        });


        msNavigationServiceProvider.saveItem('mant.entities', {
            title : 'global.menu.admin.entities',
            icon  : 'icon-cloud',
            weight: 3,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('mant.entities.users', {
            icon  : 'icon-account',
            title : 'global.menu.admin.userManagement',
            state : 'user-management',
            weight: 51
        });

        msNavigationServiceProvider.saveItem('mant.entities.apps', {
            icon  : 'icon-cellphone-android',
            title : 'global.menu.entities.application',
            state : 'application',
            weight: 52
        });

        msNavigationServiceProvider.saveItem('mant.entities.business', {
            icon  : 'icon-factory',
            title : 'global.menu.entities.business',
            state : 'businesses',
            weight: 53
        });

        msNavigationServiceProvider.saveItem('mant.entities.categories', {
            icon  : 'icon-multiplication',
            title : 'global.menu.entities.categories',
            state : 'category',
            weight: 54
        });

        msNavigationServiceProvider.saveItem('mant.entities.banners', {
            icon  : 'icon-newspaper',
            title : 'global.menu.entities.banners',
            state : 'banners',
            weight: 55
        });

        msNavigationServiceProvider.saveItem('mant.masters', {
            title : 'global.menu.masters.main',
            icon  : 'icon-table-large',
            weight: 3,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('mant.masters.countries', {
            icon  : 'icon-flag-checkered-variant',
            title : 'global.menu.masters.countries',
            state : 'country',
            weight: 61
        });

        msNavigationServiceProvider.saveItem('mant.masters.currencies', {
            icon  : 'icon-coin',
            title : 'global.menu.masters.currencies',
            state : 'currency',
            weight: 62
        });

        msNavigationServiceProvider.saveItem('mant.masters.taxes', {
            icon  : 'icon-sale',
            title : 'global.menu.masters.taxes',
            state : 'product-tax',
            weight: 63
        });
    }
})();
