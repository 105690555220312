(function ()
{
    'use strict';

    config.$inject = ["$stateProvider", "$translatePartialLoaderProvider", "msApiProvider", "msNavigationServiceProvider"];
    angular
        .module('app.e-commerce',
            [
                // 3rd Party Dependencies
                'xeditable'
            ]
        )
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider, msApiProvider, msNavigationServiceProvider)
    {

        $stateProvider.state('app.e-commerce', {
            abstract: true,
            parent: 'app'
        }).state('app.e-commerce.orders', {
            url: '/orders',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                title: 'vipWebApp.order.home.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/main/e-commerce/orders/orders.html',
                    controller: 'OrdersController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                OrderStatuses: function (eCommerceService)
                {
                    return eCommerceService.getOrderStatuses();
                },
                Currencies : function(Currency) {
                    return Currency.query();
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('order');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('app.e-commerce.products', {
            url: '/products',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                title: 'vipWebApp.order.home.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/main/e-commerce/products/products.html',
                    controller: 'ProductsController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                Products: function (Product)
                {
                    return Product.query();
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('product');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            }
        }).state('app.e-commerce.orders.detail', {
            url      : '/:id',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                title: 'vipWebApp.order.home.title'
            },
            views    : {
                'content@app': {
                    templateUrl: 'app/main/e-commerce/order/order.html',
                    controller : 'OrderController as vm'
                }
            },
            resolve  : {
                order : function ($stateParams, Order)
                {
                    return Order.get({id: $stateParams.id}).$promise;
                }
            },
            bodyClass: 'e-commerce'
        }).state('app.e-commerce.reservations', {
            parent: 'app',
            url: '/reservation',
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'vipWebApp.reservation.home.title'
            },
            views: {
                'content@app': {
                    templateUrl: 'app/main/e-commerce/reservation/reservations.html',
                    controller: 'ReservationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                ReservationStatuses: function (eCommerceService)
                {
                    return eCommerceService.getReservationStatuses();
                },
                Currencies : function(Currency) {
                    return Currency.query();
                },
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('reservation');
                    $translatePartialLoader.addPart('global');
                    return $translate.refresh();
                }]
            },
            bodyClass: 'e-commerce'
        });


        msApiProvider.register('e-commerce.order-statuses', ['app/data/e-commerce/order-statuses.json']);
        msApiProvider.register('e-commerce.reservation-statuses', ['app/data/e-commerce/reservation-statuses.json']);



        // Navigation
        msNavigationServiceProvider.saveItem('apps.products', {
            title : 'global.menu.vc.products',
            icon  : 'icon-cube-outline',
            state : 'app.e-commerce.products',
            weight: 20,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('apps.orders', {
            title : 'global.menu.vc.orders',
            icon  : 'icon-cart',
            state : 'app.e-commerce.orders',
            weight: 21,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('apps.reservation', {
            title : 'global.menu.vc.reservations',
            icon  : 'icon-checkbox-marked',
            state : 'app.e-commerce.reservations',
            weight: 22,
            authorities: ['ROLE_ADMIN']
        });


    }

})();
