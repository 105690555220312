(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('CategoryDialogController', CategoryDialogController);

    CategoryDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Category', 'Application', 'Country'];

    function CategoryDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Category, Application, Country) {
        var vm = this;

        vm.category = entity;
        vm.clear = clear;
        vm.save = save;
        vm.applications = Application.query();
        vm.countries = Country.query();

        vm.queryCountries   = queryCountries;
        vm.selectedCountryChange = selectedCountryChange;
        vm.queryApps   = queryApps;
        vm.selectedAppChange = selectedAppChange;

        vm.countries.$promise.then(function(data){
            if (vm.category.countryId!=null) {
                data.forEach(function(item) {
                    if (item.id==vm.category.countryId) vm.countrySelected = item;
                })
            }
        });

        vm.applications.$promise.then(function(data){
            if (vm.category.applicationId!=null) {
                data.forEach(function(item) {
                    if (item.id==vm.category.applicationId) vm.appSelected = item;
                })
            }
        });


        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.category.id !== null) {
                Category.update(vm.category, onSaveSuccess, onSaveError);
            } else {
                Category.save(vm.category, onSaveSuccess, onSaveError);
            }
        }

        /* AUTOCOMPLETE COUNTRIES */
        function selectedCountryChange(item) {
            vm.category.countryId = (item!==undefined) ? item.id : null;
        }

        function queryCountries (query) {
            var results = query ? vm.countries.filter( createFilterForCountry(query) ) : vm.countries;
            return results;
        }

        function createFilterForCountry(query) {
            var lowercaseQuery = query.toLowerCase();
            return function filterFn(country) {
                return (country.nameEs.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

        /* AUTOCOMPLETE APPS */
        function selectedAppChange(item) {
            vm.category.applicationId = (item!==undefined) ? item.id : null;
        }

        function queryApps (query) {
            var results = query ? vm.applications.filter( createFilterForApps(query) ) : vm.applications;
            return results;
        }

        function createFilterForApps(query) {
            var lowercaseQuery = query.toLowerCase();
            return function filterFn(app) {
                return (app.name.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

    }
})();
