(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('OfferDialogController', OfferDialogController);

    OfferDialogController.$inject = ['Upload', '$scope', '$rootScope', '$state', 'OfferDiscountService', '$translate', '$mdToast', '$mdDialog', 'entity', 'DateUtils', 'BusinessPlaceService'];

    function OfferDialogController (Upload, $scope, $rootScope, $state, OfferDiscountService, $translate, $mdToast, $mdDialog, entity, DateUtils, BusinessPlaceService) {
        var vm = this;

        vm.offer = entity;
        vm.imageToStore = false;

        vm.save = save;
        vm.prepareImage = prepareImage;

        vm.totalHours = 24;
        vm.hours = [];
        for (var i = 0; i<24; i++) {vm.hours.push( {value: i, time: i + ":00" }); }

        BusinessPlaceService.query({
            page: 0,
            size: 100,
            sort: ['id,asc']
        }, function(data) {
            vm.places = data;
        });

        if (vm.offer.id!=null) {
            vm.startHour = getHour(vm.offer.datePublishStart);
            vm.endHour = getHour(vm.offer.datePublishEnd);
        } else {
            vm.offer.datePublishEnd = new Date(); vm.offer.datePublishEnd.setMinutes(0);
            vm.offer.datePublishStart = new Date(); vm.offer.datePublishEnd.setMinutes(0);
            vm.startHour = 0;
            vm.endHour = 0;
        }

        vm.taToolbar = [
            ['quote', 'bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear', 'html'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent']
        ];

        function onSaveSuccess (result) {
            vm.isSaving = false;
            if (vm.imageToStore) {
                uploadImage(result.id)
            } else {
                $rootScope.$broadcast("menuChanged");
                $state.go('^');
            }
        }

        function save () {

            if (!validFields()) return;

            vm.isSaving = true;
            vm.offer.datePublishStart = setHour(vm.offer.datePublishStart, vm.startHour);
            vm.offer.datePublishEnd = setHour(vm.offer.datePublishEnd, vm.endHour);

            if (vm.offer.id !== null) {
                OfferDiscountService.updateOffer(vm.offer).then(onSaveSuccess);
            } else {
                OfferDiscountService.createOffer(vm.offer).then(onSaveSuccess);
            }
        }

        function getHour(dateTime) {
            return DateUtils.convertDateTimeFromServer(dateTime).getHours();
        }

        function setHour(date, hour) {
            var dateTime = new Date(date);
            dateTime.setHours(hour);
            return dateTime;
        }

        function prepareImage(file) {
            vm.file = file;
            vm.imageToStore = true;
        }


        function validFields() {
            if (vm.offer.placeId==null) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.offer.error.place'))
                        .position('top right')
                );
                return false;
            }

            if (vm.offer.datePublishEnd==null) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.offer.error.endDate'))
                        .position('top right')
                );
                return false;
            }

            var start = setHour(vm.offer.datePublishStart, vm.startHour);
            var end = setHour(vm.offer.datePublishEnd, vm.endHour);

            if ((Math.abs(end - start) / 36e5) > 24) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.offer.error.maxHours'))
                        .position('top right')
                );
                return false;
            }


            return true;
        }

        /**
         * Upload
         * Automatically triggers when files added to the uploader
         */
        function uploadImage(id) {
            if (vm.file.name!==undefined) {
                vm.uploading = true;
                Upload.upload({
                    url: '/api/offer/upload/media',
                    data: {file: vm.file, 'id': id}
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    vm.mediaFile = "";
                    $rootScope.$broadcast("menuChanged");
                    $state.go('^');
                });
            }
        }


    }
})();
