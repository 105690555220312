(function ()
{
    'use strict';

    ReadQRService.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('ReadQRService', ReadQRService);

    /** @ngInject */
    function ReadQRService($http)
    {
        var service = {
            exportToExcel: exportToExcel
        };

        return service;

        function exportToExcel(date) {
            return $http.get('/api/readQR/download', { params: {date: date}, responseType: 'arraybuffer'}).then(function (response) {
                return response;
            });

        }

    }


})();
