(function ()
{
    'use strict';

    BusinessPlaceService.$inject = ["$resource"];
    BusinessPlaceUtils.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('BusinessPlaceService', BusinessPlaceService)
        .factory('BusinessPlaceUtils', BusinessPlaceUtils);

    /** @ngInject */
    function BusinessPlaceService($resource)
    {
        var service = $resource('api/business-places/:id', {}, {
            'query': {method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    data = angular.fromJson(data);
                    return data;
                }
            },
            'save': { method:'POST' },
            'update': { method:'PUT' },
            'delete':{ method:'DELETE'}
        });

        return service;

    }

    function BusinessPlaceUtils($http)
    {
        var service = {
            countCards: countCards,
            findAvailableUser: findAvailableUser,
            findAvailablePromoter: findAvailablePromoter,
            getUsersFromPlace: getUsersFromPlace,
            addUserToPlace: addUserToPlace,
            removeUserFromPlace: removeUserFromPlace,
            updateUserRoleFromPlace: updateUserRoleFromPlace,
            getPromotersFromPlace: getPromotersFromPlace,
            addPromoterToPlace: addPromoterToPlace,
            removePromoterFromPlace: removePromoterFromPlace,
            updatePromoCode: updatePromoCode,
            updatePromoPhone: updatePromoPhone,
            getMenuTotals: getMenuTotals,
            getPartyDaysFromPlace: getPartyDaysFromPlace,
            updatePartyDescriptions: updatePartyDescriptions,
            deletePartyDayFromPlace: deletePartyDayFromPlace,
            getBonusInfo: getBonusInfo,
        };

        return service;

        function countCards(id) {
            return $http.get('/api/business-codes/count/' + id).then(function (response) {
                return response.data;
            });
        }

        function findAvailableUser(search) {
            return $http.get('/api/_search/users/backend/' + search).then(function (response) {
                return response.data;
            });
        }

        function findAvailablePromoter(search) {
            return $http.get('/api/_search/users/backend/promoter/' + search).then(function (response) {
                return response.data;
            });
        }

        function getUsersFromPlace(placeId) {
            return $http.get("/api/_search/business-places/backend/users/" + placeId).then(function (response) {
                return response.data;
            });
        }

        function addUserToPlace(placeId, userId) {
            return $http.post("/api/business-places/backend/users/" + placeId, userId).then(function (response) {
                return response.data;
            });
        }

        function removeUserFromPlace(placeId, userId) {
            return $http.delete("/api/business-places/backend/users/" + placeId + "/" + userId).then(function (response) {
                return response.data;
            });
        }

        function updateUserRoleFromPlace(placeId, userId, authority) {
            return $http.post("/api/business-places/backend/users/" + placeId + "/" + userId, authority).then(function (response) {
                return response.data;
            });
        }

        function getPromotersFromPlace(placeId) {
            return $http.get("/api/_search/business-places/backend/promoters/" + placeId).then(function (response) {
                return response.data;
            });
        }

        function updatePromoCode(placeId, userId, code) {
            return $http.post("/api/business-places/backend/promoCode/" + placeId + "/" + userId, code).then(function (response) {
                return response.data;
            });
        }

        function updatePromoPhone(placeId, userId, phone) {
            return $http.post("/api/business-places/backend/promoPhone/" + placeId + "/" + userId, phone).then(function (response) {
                return response.data;
            });
        }

        function removePromoterFromPlace(placeId, userId) {
            return $http.delete("/api/business-places/backend/promoters/" + placeId + "/" + userId).then(function (response) {
                return response.data;
            });
        }

        function addPromoterToPlace(placeId, userId) {
            return $http.post("/api/business-places/backend/promoters/" + placeId, userId).then(function (response) {
                return response.data;
            });
        }

        function getMenuTotals(placeId) {
            return $http.get("/api/main/menuBO").then(function (response) {
                return response.data;
            });
        }

        function getPartyDaysFromPlace(placeId) {
            return $http.get("/api/party-days/place/" + placeId).then(function (response) {
                return response.data;
            });
        }

        function updatePartyDescriptions(partyDays) {
            return $http.post("/api/party-days", partyDays).then(function (response) {
                return response.data;
            });
        }


        function deletePartyDayFromPlace(placeId, partyId) {
            return $http.delete("/api/party-days/place/" + placeId + "/" + partyId).then(function (response) {
                return response.data;
            });
        }

        function getBonusInfo(bonusId, placeId) {
            return $http.get("/api/business-places/" + placeId + "/bonus/" + bonusId).then(function (response) {
                return response.data;
            });
        }
    }


})();
