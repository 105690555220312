(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('BonusOrderController', BonusOrderController);

    BonusOrderController.$inject = ['$state', '$stateParams', '$scope', 'BusinessPlaceUtils', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', 'DateUtils', '$translate', '$interval'];

    function BonusOrderController($state, $stateParams, $scope, BusinessPlaceUtils, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, DateUtils, $translate, $interval) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};
        vm.total = 0;
        vm.placeId = $stateParams.placeId;

        vm.title = $translate.instant('vipWebApp.business.bonus.home.title');

        $scope.$watch("vm.dateStart", function(){
            if (vm.dtInstance.dataTable) {
                vm.dtInstance.DataTable.search(DateUtils.convertLocalDateTimeToServer(vm.dateStart));
                vm.dtInstance.reloadData();
                vm.searched=true;
            }
        });

        BusinessPlaceUtils.getBonusInfo($stateParams.id, $stateParams.placeId).then(function(data) {
           vm.title = $translate.instant('vipWebApp.business.bonus.home.title') + ": " + data.name;
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('vipWebApp.offer.orders.fullName')).notSortable().withOption('bVisible', false),
            DTColumnBuilder.newColumn('userName',$translate('vipWebApp.offer.orders.fullName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('userMail',$translate('vipWebApp.offer.orders.email')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('orderDate',$translate('vipWebApp.offer.orders.dateCreated')).notSortable().withOption("name", "orderDate").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm");
            })
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/business-places/" + $stateParams.placeId + "/bonus/" + $stateParams.id + "/orders/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'desc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

    }

})();
