(function ()
{
    'use strict';

    BusinessPlacesController.$inject = ["$state", '$scope', 'Principal', 'paginationConstants', 'Currencies', '$mdDialog', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate'];
    angular
        .module('app.businesses')
        .controller('BusinessPlacesController', BusinessPlacesController);

    /** @ngInject */
    function BusinessPlacesController($state, $scope, Principal, paginationConstants, Currencies,  $mdDialog, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate)
    {
        var vm = this;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.searchTerm="";
        vm.itemsPerPage = paginationConstants.itemsPerPage;
        vm.editBusiness = true;
        vm.canEdit = false;

        vm.bpIds = [];

        if (Principal.hasAnyAuthority(['ROLE_ADMIN','ROLE_BUSINESS'])) {
            vm.canEdit = true;
        }

        // Data
        vm.dtInstance = {};
        vm.dtColumns = [
            DTColumnBuilder.newColumn('name',$translate('BP.BUSINESS_NAME')).withOption("name", "name").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('cardName',$translate('BP.CARD_NAME')).withOption("name", "cardName").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('address',$translate('BP.ADDRESS')).withOption("name", "address").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('city',$translate('BP.CITY')).withOption("name", "city").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('total',$translate('BP.TOTAL')).withOption("name", "total").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('enabled',$translate('BP.ENABLED')).notSortable().withOption('defaultContent', '').withOption("width", "72px").renderWith(enabledHtml),
            DTColumnBuilder.newColumn('vipRequest',$translate('BP.VIP_REQUEST')).notSortable().withOption('defaultContent', '').withOption("width", "72px").renderWith(enabledHtml),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '250px').notSortable().renderWith(actionsHtml)
        ];

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.bpIds = [];
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.bpIds = [];
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/business-places/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(vm.itemsPerPage)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            vm.bpIds.push(data.id);
            var css = "appcard-" + data.applicationId;
            $compile(angular.element(row).addClass(css).contents())($scope);
        }

        function logoHtml(data, type, full, meta) {
            return '<img class="business-image" src="' + data + '">';
        }

        function priceHtml(data, type, full, meta) {
            return GUIUtils.formatCurrency(data, 3701, Currencies);
        }

        function actionsHtml(data, type, full, meta){
            var html = (vm.canEdit) ?
                        ('<button class="md-button edit-button md-icon-button" ng-click="vm.gotoBusinessDetail(' + data.id + ')" translate translate-attr-aria-label="BP.BUSINESS_DETAILS">' +
                                '<md-tooltip md-direction="bottom">VER / EDITAR</md-tooltip>' +
                                '<i class="md-pinkTheme-theme md-font material-icons icon-pencil amber-600-fg"></i>' +
                        '</button>') : '';
            html = html + '<a ui-sref="app.contacts({id: ' + data.id + '})" >' +
                '<button class="md-button edit-button md-icon-button" aria-label="Business details" translate translate-attr-aria-label="BP.BUSINESS_PUSH">' +
                '<md-tooltip md-direction="bottom">ENVIAR PUSH</md-tooltip>' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-hangouts blue-600-fg"></i>' +
                '</button>' +
                '</a>';
            return html;
        }

        function activeCardsHtml(data, type, full, meta){
            if (data < 25) return '<div class="quantity-indicator md-red-500-bg"></div>' + data + '</div>';
            else if (data < 100) return '<div class="quantity-indicator md-amber-500-bg"></div>' + data + '</div>';
            else return '<div class="quantity-indicator md-green-600-bg"></div>' + data + '</div>';
        }

        function enabledHtml(data, type, full, meta){
            return (data) ? '<i class="icon-checkbox-marked-circle green-500-fg"></i>' : '<i class="icon-cancel red-500-fg"></i>';
        }


        // Methods
        vm.gotoAddBusiness = gotoAddBusiness;
        vm.gotoBusinessDetail = gotoBusinessDetail;
        vm.sendPush = sendPush;

        //////////
        /**
         * Go to add business
         */
        function gotoAddBusiness()
        {
            $state.go('app.businesses.business.add');
        }

        /**
         * Go to business detail
         *
         * @param id
         */
        function gotoBusinessDetail(id)
        {
            $state.go('app.businesses.business.detail', {id: id});
        }

        /**
         * Delete Contact Confirm Dialog
         */
        function sendPush(all, ev)
        {
            var title = "Enviar mensaje a todos los clientes de todos los VIPS";

            $mdDialog.show({
                controller: DialogPushVIPSController,
                templateUrl: '/app/main/contacts/dialogs/push-dialog.tmpl.html',
                parent: angular.element(document.body),
                targetEvent: ev,
                clickOutsideToClose:true,
                scope: $scope.$new(),
                locals: {
                    all: all,
                    titleDialog: title
                }
            }).then(function(result) {

            }, function() {

            });
        }

        DialogPushVIPSController.$inject = ["$scope", "Upload", "$mdDialog", "UserAppService", "all", "titleDialog"];
        function DialogPushVIPSController($scope, Upload, $mdDialog, UserAppService, all, titleDialog) {

            $scope.titleDialog = titleDialog;
            $scope.advanced = false;
            $scope.imageError = false;
            $scope.pushLinkUrl = "";
            $scope.pushImageUrl = "";
            $scope.mediaFile = "";
            $scope.errorFile = "";

            var placeId = -1000;

            $scope.hide = function () {
                $mdDialog.hide();
            };

            $scope.cancel = function () {
                $mdDialog.cancel();
            };

            $scope.toggleAdvanced = function () {
                $scope.advanced = !$scope.advanced;
            };

            $scope.prepareImage = function (file) {
                $scope.file = file;
            };

            $scope.answer = function (answer) {
                if (answer === "ok") {
                    var data = {users: [], message: $scope.messageToPush, link: null, imageUrl: null, bp: null};
                    var validateImage = false;

                    // Envio mensaje Push al usuarios
                    data.bp = placeId;
                    data.bps = vm.bpIds;

                    if ($scope.advanced) {
                        data.link = $scope.pushLinkUrl;
                        data.imageUrl = $scope.pushImageUrl;
                        data.mediaFile = $scope.mediaFile;

                        // Revisamos que si tenemos una imagen esta sea válida
                        if (data.imageUrl.trim() !== "") {
                            validateImage = true;
                            testImage(data.imageUrl, function (url, result) {
                                if (result === "success") {
                                    UserAppService.pushMessage(placeId, data);
                                    $mdDialog.hide(answer);
                                } else {
                                    $scope.imageError = true;
                                }
                            });
                        } else if (data.mediaFile && data.mediaFile.name && data.mediaFile.name.trim() !== "") {
                            validateImage = true;
                            Upload.upload({
                                url: 'api/user-apps/business/' + placeId + '/pushMedia',
                                data: {file: $scope.file, 'id': placeId}
                            }).progress(function (evt) {
                                var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                                console.log('progress: ' + progressPercentage + '% ');
                            }).success(function (result, status, headers, config) {
                                data.imageUrl = result.value;
                                UserAppService.pushMessage(placeId, data);
                                $mdDialog.hide(answer);
                            });
                        }
                    }

                    if (validateImage === false) {
                        UserAppService.pushMessage(placeId, data);
                        $mdDialog.hide(answer);
                    }
                } else {
                    $mdDialog.hide(answer);
                }
            };

            function testImage(url, callback, timeout) {
                timeout = timeout || 5000;
                var timedOut = false, timer;
                var img = new Image();
                img.onerror = img.onabort = function () {
                    if (!timedOut) {
                        clearTimeout(timer);
                        callback(url, "error");
                    }
                };
                img.onload = function () {
                    if (!timedOut) {
                        clearTimeout(timer);
                        callback(url, "success");
                    }
                };
                img.src = url;
                timer = setTimeout(function () {
                    timedOut = true;
                    // reset .src to invalid URL so it stops previous
                    // loading, but doesn't trigger new load
                    img.src = "//!!!!/test.jpg";
                    callback(url, "timeout");
                }, timeout);
            }
        }
    }
})();
