(function () {
    'use strict';

    angular
        .module('vipWebApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider', 'msNavigationServiceProvider'];

    function stateConfig ($stateProvider, msNavigationServiceProvider) {
        $stateProvider.state('admin', {
            abstract: true,
            parent: 'app'
        });

        // Navigation
        msNavigationServiceProvider.saveItem('mant', {
            title : 'Settings',
            group : true,
            weight: 50,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('mant.system', {
            title : 'global.menu.admin.system',
            icon  : 'icon-cog',
            weight: 3
        });

        msNavigationServiceProvider.saveItem('mant.system.audits', {
            icon  : 'icon-gavel',
            title : 'global.menu.admin.audits',
            state : 'audits',
            weight: 61
        });

        msNavigationServiceProvider.saveItem('mant.system.configuration', {
            icon  : 'icon-barley',
            title : 'global.menu.admin.configuration',
            state : 'jhi-configuration',
            weight: 62
        });

        msNavigationServiceProvider.saveItem('mant.system.docs', {
            icon  : 'icon-document',
            title : 'global.menu.admin.apidocs',
            state : 'docs',
            weight: 63
        });

        msNavigationServiceProvider.saveItem('mant.system.health', {
            icon  : 'icon-heart',
            title : 'global.menu.admin.health',
            state : 'jhi-health',
            weight: 64
        });

        msNavigationServiceProvider.saveItem('mant.system.logs', {
            icon  : 'icon-server',
            title : 'global.menu.admin.logs',
            state : 'logs',
            weight: 65
        });

        msNavigationServiceProvider.saveItem('mant.system.metrics', {
            icon  : 'icon-chart-bar',
            title : 'global.menu.admin.metrics',
            state : 'jhi-metrics',
            weight: 66
        });


    }
})();
