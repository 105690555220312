(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('ApplicationDialogController', ApplicationDialogController);

    ApplicationDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Application'];

    function ApplicationDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Application) {
        var vm = this;

        vm.application = entity;
        vm.clear = clear;
        vm.save = save;
        vm.clearUUID = clearUUID;

        function clearUUID() {
            vm.application.uuid = "";
        }

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.application.id !== null) {
                Application.update(vm.application, onSaveSuccess, onSaveError);
            } else {
                Application.save(vm.application, onSaveSuccess, onSaveError);
            }
        }

    }
})();
