(function ()
{
    'use strict';

    OfferDiscountService.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('OfferDiscountService', OfferDiscountService);

    /** @ngInject */
    function OfferDiscountService($http)
    {
        var service = {
            getOffer: getOffer,
            getOffers: getOffers,
            getDiscount: getDiscount,
            getOfferInfo: getOfferInfo,
            getDiscountInfo: getDiscountInfo,
            createOffer: createOffer,
            createDiscount: createDiscount,
            updateOffer: updateOffer,
            updateDiscount: updateDiscount,
            deleteOffer: deleteOffer,
            deleteDiscount: deleteDiscount
        };

        return service;

        function getOffer(id) {
            return $http.get('/api/offer/' + id).then(function (response) {
                return response.data;
            });
        }

        function getOffers(id) {
            return $http.get('/api/offer/place/' + id).then(function (response) {
                return response.data;
            });
        }

        function getDiscount(id) {
            return $http.get('/api/discount/' + id).then(function (response) {
                return response.data;
            });
        }

        function getOfferInfo(offerId) {
            return $http.get('/api/offer/' + offerId + "/info").then(function (response) {
                return response.data;
            });
        }

        function getDiscountInfo(discountId) {
            return $http.get('/api/discount/' + discountId + "/info").then(function (response) {
                return response.data;
            });
        }

        function createOffer(offer) {
            return $http.post("/api/offer", offer).then(function (response) {
                return response.data;
            });
        }

        function updateOffer(offer) {
            return $http.put("/api/offer", offer).then(function (response) {
                return response.data;
            });
        }

        function createDiscount(discount) {
            return $http.post("/api/discount", discount).then(function (response) {
                return response.data;
            });
        }

        function updateDiscount(discount) {
            return $http.put("/api/discount", discount).then(function (response) {
                return response.data;
            });
        }

        function deleteOffer(id) {
            return $http.delete("/api/offer/" + id).then(function (response) {
                return response.data;
            });
        }

        function deleteDiscount(id) {
            return $http.delete("/api/discount/" + id).then(function (response) {
                return response.data;
            });
        }

    }


})();
