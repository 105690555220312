(function() {
    'use strict';
    angular
        .module('vipWebApp')
        .factory('BusinessRequest', BusinessRequest)
        .factory('BusinessRequestService', BusinessRequestService);

    BusinessRequest.$inject = ['$resource'];
    BusinessRequestService.$inject = ['$http'];

    function BusinessRequest ($resource) {
        var resourceUrl =  'api/business-place-requests/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }

    function BusinessRequestService ($http) {
        var service = {
            accept: accept,
            deny: deny
        };

        return service;

        function accept(id, placeId) {
            return $http.post('api/business-place-requests/accept/' + placeId, id).then(function (response) {
                return response.data;
            });
        }

        function deny(id, placeId) {
            return $http.post('api/business-place-requests/deny/' + placeId, id).then(function (response) {
                return response.data;
            });
        }
    }
})();
