(function() {
    /*jshint bitwise: false*/
    'use strict';

    angular
        .module('vipWebApp')
        .factory('DTHeader', dtHeader);

    dtHeader.$inject = ['$rootScope', '$q', '$location', '$localStorage', '$sessionStorage'];

    function dtHeader ($rootScope, $q, $location, $localStorage, $sessionStorage) {
        var service = {
            request: request
        };

        return service;

        function request() {
            var config = {};
            var token = $localStorage.authenticationToken || $sessionStorage.authenticationToken;
            if (token) {
                config = { "Authorization": 'Bearer ' + token};
            }
            return config;
        }
    }

})();
