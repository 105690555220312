(function() {
    'use strict';
    angular
        .module('vipWebApp')
        .factory('BusinessCode', BusinessCode)
        .factory('BusinessCodeService', BusinessCodeService);

    BusinessCodeService.$inject = ['$http'];
    BusinessCode.$inject = ['$resource'];

    function BusinessCode ($resource) {
        var resourceUrl =  'api/business-codes/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }


    function BusinessCodeService ($http) {
        var service = {
            'generateCode': generateCode,
            'generateMultipleCodes': generateMultipleCodes,
            'getCodes': getCodes
        };

        return service;

        function generateCode(data) {
            return $http.post('api/business-codes', data).then(function (response) {
                return response.data;
            });
        }

        function generateMultipleCodes(data) {
            return $http.post('api/business-codes/multiple', data).then(function (response) {
                return response.data;
            });
        }

        function getCodes(placeId, used) {
            return $http.get('api/business-codes/place/' + placeId + "/" + used).then(function (response) {
                return response.data;
            });
        }

    }
})();
