(function ()
{
    'use strict';

    TastingService.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('TastingService', TastingService);

    /** @ngInject */
    function TastingService($http)
    {
        var service = {
            createTasting: createTasting,
            getTasting: getTasting,
            getTastingInfo: getTastingInfo,
            updateTasting: updateTasting,
            deleteTasting: deleteTasting
        };

        return service;

        function getTasting(id) {
            return $http.get('/api/tasting/' + id).then(function (response) {
                return response.data;
            });
        }

        function getTastingInfo(tastingId) {
            return $http.get('/api/tasting/' + tastingId + "/info").then(function (response) {
                return response.data;
            });
        }

        function createTasting(tasting) {
            return $http.post("/api/tasting", tasting).then(function (response) {
                return response.data;
            });
        }

        function updateTasting(tasting) {
            return $http.put("/api/tasting", tasting).then(function (response) {
                return response.data;
            });
        }

        function deleteTasting(id) {
            return $http.delete("/api/tasting/" + id).then(function (response) {
                return response.data;
            });
        }

    }


})();
