(function ()
{
    'use strict';

    BusinessPlaceMediaUtils.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('BusinessMediaUtils', BusinessPlaceMediaUtils);

    /** @ngInject */
    function BusinessPlaceMediaUtils($http)
    {
        var service = {
            getMediaFromPlace: getMediaFromPlace,
            updateTitle: updateTitle,
            updateMultipleTitle: updateMultipleTitle,
            updatePosition: updatePosition,
            deleteMedia: deleteMedia,
            deleteMultipleMedia: deleteMultipleMedia
        };

        return service;

        function getMediaFromPlace(placeId) {
            return $http.get('/api/business-place-medias/place/' + placeId).then(function (response) {
                return response.data;
            });
        }

        function updateTitle(imageId, title) {
            return $http.post("/api/business-place-medias/" + imageId + "/title", title).then(function (response) {
                return response.data;
            });
        }

        function updateMultipleTitle(ids, title) {
            var data = { ids: ids, text: title };
            return $http.post("/api/business-place-medias/title/multiple", data).then(function (response) {
                return response.data;
            });
        }

        function deleteMultipleMedia(ids) {
            var data = { ids: ids };
            return $http.post("/api/business-place-medias/delete/multiple", data).then(function (response) {
                return response.data;
            });
        }

        function updatePosition(imageId, position) {
            return $http.post("/api/business-place-medias/" + imageId + "/position", position).then(function (response) {
                return response.data;
            });
        }

        function deleteMedia(imageId) {
            return $http.delete("/api/business-place-medias/" + imageId).then(function (response) {
                return response.data;
            });
        }

    }


})();
