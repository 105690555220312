(function() {
    'use strict';
    angular
        .module('app.contacts')
        .factory('UserAppService', UserAppService);

    UserAppService.$inject = ['$q', '$http'];

    function UserAppService($q, $http) {

        var service = {
            pushMessage: pushMessage,
            getContacts: getContacts,
            getUsers: getUsers,
            createGroup: createGroup,
            renameGroup: renameGroup,
            deleteGroup: deleteGroup,
            addUserToGroup: addUserToGroup,
            removeUserFromGroup: removeUserFromGroup,
            starUser: starUser,
            deleteContact: deleteContact,
            exportToExcel: exportToExcel
        };

        return service;

        function pushMessage(id, data) {
            return $http.post('api/user-apps/business/' + id + '/pushMessage', data).then(function(response) {
                return response.data;
            });
        }

        function getContacts(placeId) {
            return $http.get('api/user-apps/business-place/' + placeId + '/contacts').then(function(response) {
                return response.data;
            });
        }

        function getUsers(placeId) {
            return $http.get('api/user-apps/business-place/' + placeId + '/users').then(function(response) {
                return response.data;
            });
        }

        function createGroup(placeId, nameGroup) {
            return $http.post('api/user-apps/business-place/' + placeId + '/group', nameGroup).then(function(response) {
                return response.data;
            });
        }

        function deleteGroup(placeId, groupId) {
            return $http.delete('api/user-apps/business-place/' + placeId + '/group/' + groupId).then(function(response) {
                return response.data;
            });
        }

        function renameGroup(placeId, groupId, newName) {
            return $http.post('api/user-apps/business-place/' + placeId + '/group/' + groupId, newName).then(function(response) {
                return response.data;
            });
        }

        function addUserToGroup(placeId, groupId, userId) {
            return $http.post('api/user-apps/business-place/' + placeId + '/user/' + userId + '/group', groupId).then(function(response) {
                return response.data;
            });
        }

        function removeUserFromGroup(placeId, groupId, userId) {
            return $http.delete('api/user-apps/business-place/' + placeId + '/user/' + userId + '/group/' + groupId).then(function(response) {
                return response.data;
            });
        }

        function starUser(placeId, userId, starred) {
            return $http.post('api/user-apps/business-place/' + placeId + '/user/' + userId + '/starred', starred).then(function(response) {
                return response.data;
            });
        }

        function deleteContact(placeId, cardId) {
            return $http.delete('api/user-apps/business-place/' + placeId + '/card/' + cardId).then(function(response) {
                return response.data;
            });
        }

        function exportToExcel(data) {
            return $http.post('/api/user-apps/business-places/export/dt', data, { responseType: 'arraybuffer'}).then(function (response) {
                return response;
            });

        }

    }

})();
