(function ()
{
    'use strict';

    config.$inject = ["$stateProvider", "$translatePartialLoaderProvider", "msApiProvider", "msNavigationServiceProvider"];
    angular
        .module('app.contacts',
            [
                // 3rd Party Dependencies
                'xeditable'
            ]
        )
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider, msApiProvider, msNavigationServiceProvider)
    {

        $stateProvider.state('app.contacts', {
            url    : '/contacts/:id',
            views  : {
                'content@app': {
                    templateUrl: 'app/main/contacts/contacts.html',
                    controller : 'ContactsController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR'],
                pageTitle: 'global.menu.contacts'
            },
            resolve: {
                Contacts: ["UserAppService", "$stateParams", function (UserAppService, $stateParams)
                {
                    return UserAppService.getContacts($stateParams.id);
                }],
                User: ["UserAppService", "$stateParams", function (UserAppService, $stateParams)
                {
                    return UserAppService.getUsers($stateParams.id);
                }],
                Offers: ["$stateParams", "OfferDiscountService", function ($stateParams, OfferDiscountService)
                {
                    return OfferDiscountService.getOffers($stateParams.id);
                }]
            }
        });

        // Translation
        $translatePartialLoaderProvider.addPart('contacts');

        /* Navigation
        msNavigationServiceProvider.saveItem('apps.contacts', {
            title : 'global.menu.vc.pushmessages',
            icon  : 'icon-account-box',
            state : 'app.contacts',
            weight: 10,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
        });
        */

    }

})();
