(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('ProductTaxController', ProductTaxController);

    ProductTaxController.$inject = ['ProductTax', '$scope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate', '$mdDialog'];

    function ProductTaxController(ProductTax, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate, $mdDialog) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};

        vm.title = $translate.instant('vipWebApp.productTax.home.title');
        vm.newButtonTitle = $translate.instant('vipWebApp.productTax.home.createLabel');

        vm.addNew = addNewDialog;
        vm.edit = editEntityDialog;
        vm.deleteEntity = deleteEntity;
        vm.deleteConfirm = deleteEntityConfirm;

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id"),
            DTColumnBuilder.newColumn('nameTax',$translate('vipWebApp.productTax.nameTax')).withOption("name", "nameTax").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('rate',$translate('vipWebApp.productTax.rate')).withOption("name", "rate").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('countryId',$translate('vipWebApp.productTax.country')).withOption("name", "countryId").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('enabled',$translate('vipWebApp.productTax.enabled')).withOption("name", "enabled").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/product-taxes/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate("'" + data.id + "'", ['all']);
        }

        /**
         * Open new contact dialog
         *
         * @param ev
         * @param entity
         */
        function editEntityDialog(ev, data)
        {
            $mdDialog.show({
                templateUrl: 'app/entities/product-tax/product-tax-dialog.html',
                controller: 'ProductTaxDialogController',
                controllerAs: 'vm',
                backdrop      : 'static',
                targetEvent   : ev,
                resolve: {
                    entity: ['ProductTax', function(ProductTax) {
                        return ProductTax.get({id : data}).$promise;
                    }]
                }
            }).then(function() {
                vm.dtInstance.reloadData();
            });
        }

        /**
         * Open new dialog to create entity
         *
         */
        function addNewDialog()
        {
            $mdDialog.show({
                templateUrl: 'app/entities/product-tax/product-tax-dialog.html',
                controller: 'ProductTaxDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            nameTax: null, rate: null, countryId: null,
                            id: null
                        };
                    }
                }
            }).then(function() {
                vm.dtInstance.reloadData();
            });
        }

        /**
         * Confirm Dialog
         */
        function deleteEntityConfirm(ev, data)
        {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('global.delete.confirmation.title'))
                .htmlContent($translate.instant('global.delete.confirmation.content', {param: data}))
                .ariaLabel('Delete ProductTax')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                deleteEntity(data);
            }, function () {

            });
        }

        /**
         * Delete Entity
         */
        function deleteEntity(data)
        {
            ProductTax.delete({id: data},
                function () {
                    vm.dtInstance.reloadData();
                }
            );

        }
    }
})();
