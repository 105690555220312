(function () {
    'use strict';

    angular
        .module('vipWebApp')

        /*
         Languages codes are ISO_639-1 codes, see http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
         They are written in English to avoid character encoding issues (not a perfect solution)
         */
        .constant('LANGUAGES', [
            'es',
            'en',
            'fr'
            // jhipster-needle-i18n-language-constant - JHipster will add/remove languages in this array
        ])
        .constant('LANGUAGESCODES', {
            en: {
                'title'      : 'English',
                'translation': 'TOOLBAR.ENGLISH',
                'code'       : 'en',
                'flag'       : 'us'
            },
            es: {
                'title'      : 'Spanish',
                'translation': 'TOOLBAR.SPANISH',
                'code'       : 'es',
                'flag'       : 'es'
            },
            fr: {
                'title'      : 'French',
                'translation': 'TOOLBAR.FRENCH',
                'code'       : 'fr',
                'flag'       : 'fr'
            }
        });
})();
