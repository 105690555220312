(function() {
    'use strict';

    angular
        .module('app.e-commerce')
        .controller('ReservationController', ReservationController);

    ReservationController.$inject = ['Reservation', 'ReservationStatuses', 'Currencies', 'SelectedBusiness', '$scope', '$filter', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', '$compile', '$translate', 'GUIUtils', 'DateUtils'];

    function ReservationController(Reservation, ReservationStatuses, Currencies, SelectedBusiness, $scope, $filter, DTColumnBuilder , DTOptionsBuilder, DTHeader, $compile, $translate, GUIUtils, DateUtils) {

        var vm = this;

        // Data
        vm.dtInstance = {};
        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id"),
            DTColumnBuilder.newColumn('reservationSku',$translate('vipWebApp.reservation.reservationSku')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('userName',$translate('vipWebApp.reservation.user')).withOption("name", "user.user.firstName").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('numberGuests',$translate('vipWebApp.reservation.numberGuests')).withOption("name", "numberGuests").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('dateReservation',$translate('vipWebApp.reservation.dateReservation')).withOption("name", "dateReservation").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy")
            }),
            DTColumnBuilder.newColumn('timeReservation',$translate('vipWebApp.reservation.timeReservation')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('estimatedExpend',$translate('vipWebApp.reservation.estimatedExpend')).notSortable().withOption('defaultContent', '').renderWith(priceHtml),
            DTColumnBuilder.newColumn('status',$translate('vipWebApp.reservation.reservationStatus')).notSortable().withOption('defaultContent', '').renderWith(statusHtml),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', getAjaxForm())
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function priceHtml(data, type, full, meta) {
            return GUIUtils.formatCurrency(data, full.currencyId, Currencies);
        }

        function statusHtml(data, type, full, meta){
            // Show last order status
            var reservationStatus = ReservationStatuses[data];
            if ( type === 'display' ) {
                return '<span class="status ' + reservationStatus.color + '" data-translate="' + reservationStatus.name + '">' + reservationStatus.name + ' </span>';
            }
            if ( type === 'filter' ) {
                return reservationStatus.name;
            }
            return data;
        }

        function actionsHtml(data, type, full, meta){
            var html = '<md-button class="edit-button md-icon-button" ng-click="vm.gotoReservationDetail(\'' + data.id + '\')" aria-label="Order details" ' +
                'translate translate-attr-aria-label="EC.ORDER_DETAILS">' +
                '<md-icon md-font-icon="icon-dots-horizontal" class="s16"></md-icon>' +
                '</md-button>';
            return html;
        }


        $scope.$on('businessChanged', function() {
            vm.dtOptions.withOption('ajax', getAjaxForm());
            vm.dtInstance.rerender();
        });


        // Methods
        vm.gotoReservationDetail = gotoReservationDetail;

        function getAjaxForm() {
            return {
                url: "/api/reservations/dt",
                data: {
                    businessId: SelectedBusiness.getBusiness().id
                },
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            };
        }

        /**
         * Go to product detail
         *
         * @param id
         */
        function gotoReservationDetail(id)
        {
            $state.go('app.e-commerce.orders.detail', {id: id});
        }

    }
})();
