(function ()
{
    'use strict';

    VisitService.$inject = ["$http"];

    angular
        .module('app.businesses')
        .factory('VisitService', VisitService);

    /** @ngInject */
    function VisitService($http)
    {
        var service = {
            createVisit: createVisit,
            getVisit: getVisit,
            getVisitInfo: getVisitInfo,
            updateVisit: updateVisit,
            deleteVisit: deleteVisit
        };

        return service;

        function getVisit(id) {
            return $http.get('/api/visit/' + id).then(function (response) {
                return response.data;
            });
        }

        function getVisitInfo(visitId) {
            return $http.get('/api/visit/' + visitId + "/info").then(function (response) {
                return response.data;
            });
        }

        function createVisit(visit) {
            return $http.post("/api/visit", visit).then(function (response) {
                return response.data;
            });
        }

        function updateVisit(visit) {
            return $http.put("/api/visit", visit).then(function (response) {
                return response.data;
            });
        }

        function deleteVisit(id) {
            return $http.delete("/api/visit/" + id).then(function (response) {
                return response.data;
            });
        }

    }


})();
