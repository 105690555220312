(function ()
{
    'use strict';

    IndexController.$inject = ["fuseTheming"];
    angular
        .module('vipWebApp')
        .controller('IndexController', IndexController);

    /** @ngInject */
    function IndexController(fuseTheming)
    {
        var vm = this;

        // Data
        vm.themes = fuseTheming.themes;
        vm.themes.active.name="pinkTheme";

        //////////
    }
})();
