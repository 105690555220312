(function ()
{
    'use strict';

    config.$inject = ["$stateProvider", "$translatePartialLoaderProvider", "msApiProvider", "msNavigationServiceProvider"];
    angular
        .module('app.businesses',
            [
                // 3rd Party Dependencies
                'nvd3',
                'textAngular',
                'uiGmapgoogle-maps',
                'xeditable',
                'mdColorPicker'
            ]
        )
        .config(config);

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider, msApiProvider, msNavigationServiceProvider)
    {
        // State
        $stateProvider
            .state('app.businesses', {
                abstract: true,
                url     : '/business'
            })
            .state('app.businesses.dashboard', {
                url      : '/dashboard',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/dashboard/dashboard.html',
                        controller : 'DashboardBusinessesController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.dashboard'
                },
                resolve  : {
                    Dashboard: ["msApi", function (msApi)
                    {
                        return msApi.resolve('businesses.dashboard@get');
                    }]
                },
                bodyClass: 'business'
            })
            .state('app.businesses.readQR', {
                url      : '/readQR',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/lectorqr/lectorqr.html',
                        controller : 'ReadQRController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR', 'ROLE_QR_CHECK'],
                    pageTitle: 'global.menu.vc.readQR'
                },
                bodyClass: 'business'
            })
            .state('app.businesses.business', {
                url      : '/businesses',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/businesses/business-places.html',
                        controller : 'BusinessPlacesController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR'],
                    pageTitle: 'global.menu.vc.businesses'
                },
                params: {
                    page: {
                        value: '1',
                        squash: true
                    },
                    sort: {
                        value: 'id,asc',
                        squash: true
                    }
                },
                resolve: {
                    Currencies : function(Currency) {
                        return Currency.query();
                    }
                },
                bodyClass: 'business'
            })
            .state('app.businesses.businessCode', {
                url      : '/codes',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/businesses/business-places.html',
                        controller : 'BusinessPlacesCodesController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessesCode'
                },
                params: {

                },
                resolve: {
                    Currencies : function(Currency) {
                        return Currency.query();
                    }
                },
                bodyClass: 'business'
            })
            .state('app.businesses.business.add', {
                url      : '/add',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/business/business-place.html',
                        controller : 'BusinessPlaceController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN'],
                    pageTitle: 'global.menu.vc.businesses.add'
                },
                resolve: {
                    BusinessPlace: ["BusinessPlaceService", function (BusinessPlaceService)
                    {
                        var business = {
                            enabled: false, vipRequest: false, withCode: true, price: 0, defaultExpirationDays: 365,
                            mandatoryData: false, mandatoryCardId: false, mandatoryDepto: false, mandatoryLocal: false, mandatoryPhoto: false,
                            minimumAge: 0, downloadWithCode: false, familyCard: false,
                            cardTemplate: { 'cardPattern':'', 'backgroundColor':'#E7E6E6',
                                'cardTemplate': '1', 'cardColor':'#FFFFFF', 'cardLineColor':'#000000',
                                'fontCardColor':'#000000', 'fontTitleColor':'#FFFFFF', 'fontNumberColor':"#000000",
                                'cardBackColor': "#FFFFFF", 'fontCardBackColor':"#000000", 'fontTitleBackColor':'#000000'
                            },
                            links: {frontLinks: [], moreInfo: []}
                        };
                        return business;
                    }],
                    UsersAssigned: [function ()
                    {
                        return [];
                    }],
                    PromotersAssigned: [function ()
                    {
                        return [];
                    }],
                    MediaImages: ["$stateParams", "BusinessMediaUtils", function ($stateParams, BusinessMediaUtils)
                    {
                        return [];
                    }],
                    PartyDays: [function() {
                        return [];
                    }],
                    MediaType: ["msApi", function (msApi)
                    {
                        return msApi.resolve('businesses.mediaType@get');
                    }]
                },
                bodyClass: 'business'
            })
            .state('app.businesses.business.detail', {
                url      : '/:id',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/business/business-place.html',
                        controller : 'BusinessPlaceController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businesses.edit'
                },
                resolve  : {
                    BusinessPlace: ["$stateParams", "BusinessPlaceService", function ($stateParams, BusinessPlaceService)
                    {
                        return BusinessPlaceService.get({id: $stateParams.id}).$promise;
                    }],
                    UsersAssigned: ["$stateParams", "BusinessPlaceUtils", function ($stateParams, BusinessPlaceUtils)
                    {
                        return BusinessPlaceUtils.getUsersFromPlace($stateParams.id);
                    }],
                    PromotersAssigned: ["$stateParams", "BusinessPlaceUtils", function ($stateParams, BusinessPlaceUtils)
                    {
                        return BusinessPlaceUtils.getPromotersFromPlace($stateParams.id);
                    }],
                    MediaImages: ["$stateParams", "BusinessMediaUtils", function ($stateParams, BusinessMediaUtils)
                    {
                        return BusinessMediaUtils.getMediaFromPlace($stateParams.id);
                    }],
                    PartyDays: ["$stateParams", "BusinessPlaceUtils", function ($stateParams, BusinessPlaceUtils) {
                        return BusinessPlaceUtils.getPartyDaysFromPlace($stateParams.id);
                    }],
                    MediaType: ["msApi", function (msApi)
                    {
                        return msApi.resolve('businesses.mediaType@get');
                    }]
                },
                bodyClass: 'business'
            }).state('app.businesses.businessCode.detailCode', {
                url      : '/:id',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/business/business-place-code.html',
                        controller : 'BusinessPlaceController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businesses.edit'
                },
                resolve  : {
                    BusinessPlace: ["$stateParams", "BusinessPlaceService", function ($stateParams, BusinessPlaceService)
                    {
                        return BusinessPlaceService.get({id: $stateParams.id}).$promise;
                    }],
                    UsersAssigned: [function ()
                    {
                        return null;
                    }],
                    PromotersAssigned: [function ()
                    {
                        return null;
                    }],
                    MediaImages: [function ()
                    {
                        return null;
                    }],
                    PartyDays: [function() {
                        return [];
                    }],
                    MediaType: ["msApi", function (msApi)
                    {
                        return msApi.resolve('businesses.mediaType@get');
                    }]
                },
                bodyClass: 'business'
            }).state('app.businesses.business.bonus', {
                url      : '/:placeId/bonus-order/:id',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/bonus/bonus-order.html',
                        controller : 'BonusOrderController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessBonus'
                },
                bodyClass: 'business'
            }).state('app.businesses.discounts', {
                url      : '/discounts',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/discount/discount.html',
                        controller : 'DiscountController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessDiscount'
                },
                bodyClass: 'business'
            }).state('app.businesses.discounts.add', {
                url      : '/new',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/discount/discount-dialog.html',
                        controller : 'DiscountDialogController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessDiscount'
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            shortDescription: "",
                            description: "",
                            urlImage: "",
                            dateStart: null,
                            dateEnd: null,
                            enabled: true,
                            placeId: null
                        };
                    }
                },
                bodyClass: 'business'
            }).state('app.businesses.discounts.edit', {
                url      : '/:id',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/discount/discount-dialog.html',
                        controller : 'DiscountDialogController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessDiscount'
                },
                resolve: {
                    entity: ["$stateParams", "OfferDiscountService", function ($stateParams, OfferDiscountService)
                    {
                        return OfferDiscountService.getDiscount($stateParams.id);
                    }]
                },
                bodyClass: 'business'
            }).state('app.businesses.discounts.orders', {
                url      : '/:id/orders',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/discount/discount-order.html',
                        controller : 'DiscountOrderController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessDiscount'
                },
                bodyClass: 'business'
            }).state('app.businesses.discounts.subscriptions', {
                url      : '/:id/subscriptions',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/discount/discount-subscription.html',
                        controller : 'DiscountSubscriptionController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessDiscount'
                },
                bodyClass: 'business'
            })            .state('app.businesses.visit', {
            url      : '/visit',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/visit/visit.html',
                    controller : 'VisitController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessVisit'
            },
            bodyClass: 'business'
        }).state('app.businesses.visit.add', {
            url      : '/new',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/visit/visit-dialog.html',
                    controller : 'VisitDialogController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessVisit'
            },
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        shortDescription: "",
                        description: "",
                        urlImage: "",
                        dateStart: null,
                        dateEnd: null,
                        enabled: true,
                        placeId: null
                    };
                }
            },
            bodyClass: 'business'
        }).state('app.businesses.visit.edit', {
            url      : '/:id',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/visit/visit-dialog.html',
                    controller : 'VisitDialogController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessVisit'
            },
            resolve: {
                entity: ["$stateParams", "VisitService", function ($stateParams, VisitService)
                {
                    return VisitService.getVisit($stateParams.id);
                }]
            },
            bodyClass: 'business'
        }).state('app.businesses.visit.orders', {
            url      : '/:id/orders',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/visit/visit-order.html',
                    controller : 'VisitOrderController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessVisit'
            },
            bodyClass: 'business'
        }).state('app.businesses.visit.subscriptions', {
            url      : '/:id/subscriptions',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/visit/visit-subscription.html',
                    controller : 'VisitSubscriptionController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS_VISITS'],
                pageTitle: 'global.menu.vc.businessVisit'
            },
            bodyClass: 'business'
        }).state('app.businesses.tasting', {
                url      : '/tasting',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/tasting/tasting.html',
                        controller : 'TastingController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS_VISITS'],
                    pageTitle: 'global.menu.vc.businessTasting'
                },
                bodyClass: 'business'
            }).state('app.businesses.tasting.add', {
            url      : '/new',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/tasting/tasting-dialog.html',
                    controller : 'TastingDialogController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessTasting'
            },
            resolve: {
                entity: function () {
                    return {
                        id: null,
                        shortDescription: "",
                        description: "",
                        urlImage: "",
                        dateStart: null,
                        dateEnd: null,
                        enabled: true,
                        placeId: null
                    };
                }
            },
            bodyClass: 'business'
        }).state('app.businesses.tasting.edit', {
            url      : '/:id',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/tasting/tasting-dialog.html',
                    controller : 'TastingDialogController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessTasting'
            },
            resolve: {
                entity: ["$stateParams", "TastingService", function ($stateParams, TastingService)
                {
                    return TastingService.getTasting($stateParams.id);
                }]
            },
            bodyClass: 'business'
        }).state('app.businesses.tasting.orders', {
            url      : '/:id/orders',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/tasting/tasting-order.html',
                    controller : 'TastingOrderController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessTasting'
            },
            bodyClass: 'business'
        }).state('app.businesses.tasting.subscriptions', {
            url      : '/:id/subscriptions',
            views    : {
                'content@app': {
                    templateUrl: 'app/main/businessplace/views/tasting/tasting-subscription.html',
                    controller : 'TastingSubscriptionController as vm'
                }
            },
            data: {
                authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                pageTitle: 'global.menu.vc.businessTasting'
            },
            bodyClass: 'business'
        }).state('app.businesses.offers', {
                url      : '/offer',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/offer/offer.html',
                        controller : 'OfferController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessOffer'
                },
                bodyClass: 'business'
            }).state('app.businesses.offers.add', {
                url      : '/new',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/offer/offer-dialog.html',
                        controller : 'OfferDialogController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessOffer'
                },
                resolve: {
                    entity: function () {
                        return {
                            id: null,
                            shortDescription: "",
                            description: "",
                            urlImage: "",
                            datePublishEnd: null,
                            placeId: null,
                        };
                    }
                },
                bodyClass: 'business'
            }).state('app.businesses.offers.edit', {
                url      : '/:id',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/offer/offer-dialog.html',
                        controller : 'OfferDialogController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessOffer'
                },
                resolve: {
                    entity: ["$stateParams", "OfferDiscountService", function ($stateParams, OfferDiscountService)
                    {
                        return OfferDiscountService.getOffer($stateParams.id);
                    }]
                },
                bodyClass: 'business'
            }).state('app.businesses.offers.orders', {
                url      : '/:id/orders',
                views    : {
                    'content@app': {
                        templateUrl: 'app/main/businessplace/views/offer/offer-order.html',
                        controller : 'OfferOrderController as vm'
                    }
                },
                data: {
                    authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                    pageTitle: 'global.menu.vc.businessOffer'
                },
                bodyClass: 'business'
            });


        // Translation
        $translatePartialLoaderProvider.addPart('business');
        $translatePartialLoaderProvider.addPart('readQR');
        $translatePartialLoaderProvider.addPart('offerDiscount');

        // Api
        msApiProvider.register('businesses.dashboard', ['app/data/businesses/dashboard.json']);
        msApiProvider.register('businesses.mediaType', ['app/data/businesses/mediatype.json']);


        // Navigation
        msNavigationServiceProvider.saveItem('apps', {
            title : 'APPS',
            group : true,
            weight: 1,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR', 'ROLE_QR_CHECK']
        });

        msNavigationServiceProvider.saveItem('apps.dashboard', {
            icon  : 'icon-desktop-mac',
            title: 'global.menu.vc.dashboard',
            state: 'app.businesses.dashboard',
            weight: 10,
            authorities: ['ROLE_ADMIN']
        });

        msNavigationServiceProvider.saveItem('apps.readQR', {
            icon  : 'icon-barcode',
            title: 'global.menu.vc.readQR',
            state: 'app.businesses.readQR',
            weight: 7,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR', 'ROLE_QR_CHECK']
        });

        msNavigationServiceProvider.saveItem('apps.businesses', {
            icon  : 'icon-credit-card',
            title: 'global.menu.vc.businesses',
            state: 'app.businesses.business',
            weight: 5,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_PROMOTOR']
        });

        msNavigationServiceProvider.saveItem('apps.businessesCode', {
            icon  : 'icon-qrcode',
            title: 'global.menu.vc.businessesCode',
            state: 'app.businesses.businessCode',
            weight: 6,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
        });

        msNavigationServiceProvider.saveItem('apps.businessDiscounts', {
            icon  : 'icon-percent',
            title: 'global.menu.vc.businessDiscount',
            state: 'app.businesses.discounts',
            weight: 8,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
        });

        msNavigationServiceProvider.saveItem('apps.visit', {
            icon  : 'icon-percent',
            title: 'global.menu.vc.visit',
            state: 'app.businesses.visit',
            weight: 9,
            authorities: ['ROLE_ADMIN', 'ROLE_WINE']
        });

        msNavigationServiceProvider.saveItem('apps.tasting', {
            icon  : 'icon-percent',
            title: 'global.menu.vc.tasting',
            state: 'app.businesses.tasting',
            weight: 9,
            authorities: ['ROLE_ADMIN', 'ROLE_WINE']
        });


        // Updated on toolbar.controller.js
        msNavigationServiceProvider.saveItem('apps.businessOffers', {
            icon  : 'icon-gift',
            title: 'global.menu.vc.businessOffer',
            state: 'app.businesses.offers',
            weight: 11,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
        });
    }
})();
