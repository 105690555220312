(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('DiscountDialogController', DiscountDialogController);

    DiscountDialogController.$inject = ['Upload', '$state', '$rootScope', 'OfferDiscountService', '$translate', '$mdToast', '$mdDialog', 'entity', 'DateUtils', 'BusinessPlaceService'];

    function DiscountDialogController (Upload, $state, $rootScope, OfferDiscountService, $translate, $mdToast, $mdDialog, entity, DateUtils, BusinessPlaceService) {
        var vm = this;

        vm.discount = entity;
        vm.imageToStore = false;

        vm.save = save;
        vm.prepareImage = prepareImage;

        vm.hours = [];
        for (var i = 0; i<24; i++) {vm.hours.push( {value: i, time: i + ":00" }); }

        BusinessPlaceService.query({
            page: 0,
            size: 100,
            sort: ['id,asc']
        }, function(data) {
            vm.places = data;
        });

        if (vm.discount.id !== null) {
            vm.discount.dateStart = DateUtils.convertDateTimeFromServer(vm.discount.dateStart);
            vm.discount.dateEnd = DateUtils.convertDateTimeFromServer(vm.discount.dateEnd);
            vm.startHour = getHour(vm.discount.dateStart);
            vm.endHour = getHour(vm.discount.dateEnd);
        } else {
            vm.discount.dateStart = new Date(); vm.discount.dateStart.setMinutes(0);
            vm.discount.dateEnd = new Date(); vm.discount.dateEnd.setMinutes(0);
            vm.startHour = 0;
            vm.endHour = 0;
        }

        vm.taToolbar = [
            ['quote', 'bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear', 'html'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent']
        ];

        function onSaveSuccess (result) {
            vm.isSaving = false;
            if (vm.imageToStore) {
                uploadImage(result.id);
            } else {
                $rootScope.$broadcast("menuChanged");
                $state.go('^');
            }
        }

        function save () {

            if (!validFields()) return;

            vm.isSaving = true;
            vm.discount.dateStart = setHour(vm.discount.dateStart, vm.startHour);
            vm.discount.dateEnd = setHour(vm.discount.dateEnd, vm.endHour);

            if (vm.discount.id !== null) {
                OfferDiscountService.updateDiscount(vm.discount).then(onSaveSuccess);
            } else {
                OfferDiscountService.createDiscount(vm.discount).then(onSaveSuccess);
            }
        }

        function getHour(dateTime) {
            return dateTime.getHours();
        }

        function setHour(date, hour) {
            var dateTime = new Date(date);
            dateTime.setHours(hour);
            return dateTime;
        }

        function prepareImage(file) {
            vm.file = file;
            vm.imageToStore = true;
        }


        function validFields() {
            if (vm.discount.placeId === null) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.discount.error.place'))
                        .position('top right')
                );
                return false;
            }

            if (vm.discount.dateStart === null) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.discount.error.startDate'))
                        .position('top right')
                );
                return false;
            }

            if (vm.discount.dateEnd === null) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.discount.error.endDate'))
                        .position('top right')
                );
                return false;
            }

            var dateS = new Date(vm.discount.dateStart);
            var dateE = new Date(vm.discount.dateEnd);
            dateS = setHour(vm.discount.dateStart, vm.startHour);
            dateE = setHour(vm.discount.dateEnd, vm.endHour);
            if (dateS >= dateE) {
                $mdToast.show(
                    $mdToast.simple()
                        .textContent($translate.instant('vipWebApp.discount.error.startEnd'))
                        .position('top right')
                );
                return false;
            }

            return true;
        }

        /**
         * Upload
         * Automatically triggers when files added to the uploader
         */
        function uploadImage(id) {
            if (vm.file.name!==undefined) {
                vm.uploading = true;
                Upload.upload({
                    url: '/api/discount/upload/media',
                    data: {file: vm.file, 'id': id}
                }).progress(function (evt) {
                    var progressPercentage = parseInt(100.0 * evt.loaded / evt.total);
                    console.log('progress: ' + progressPercentage + '% ');
                }).success(function (data, status, headers, config) {
                    vm.mediaFile = "";
                    $rootScope.$broadcast("menuChanged");
                    $state.go('^');
                });
            }
        }

    }
})();
