(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('CustomerController', CustomerController);

    CustomerController.$inject = ['$state', '$scope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate', 'DateUtils', 'UserAppService', '$mdDialog', '$document'];

    function CustomerController($state, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate, DateUtils, UserAppService, $mdDialog, $document) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};
        vm.status = "";
        vm.vipFilter = "";
        vm.dateStart = "";

        vm.showMore = showMore;
        vm.deleteConfirm = deleteEntityConfirm;
        vm.openUser = openUser;
        vm.exportData = exportData;
        vm.applyFilter = applyFilter;

        vm.title = $translate.instant('vipWebApp.customer.home.title');

        function filtrar() {
            vm.places = [];
            const filter = vm.searchTerm + "|" + vm.status + "|" + vm.vipFilter + "|" + DateUtils.convertLocalDateTimeToServer(vm.dateStart);
            vm.dtInstance.DataTable.search(filter);
            vm.dtInstance.reloadData();
            vm.searched=true;
        }

        function applyFilter(state) {
            vm.status = state;
            filtrar();
        }

        $scope.$watch('vm.dateStart', function(){
            filtrar();
        });

        $scope.$watch('vm.vipFilter', function(){
            if (vm.vipFilter == '') vm.dateStart = '';
            filtrar();
        });

        $scope.$watch('vm.searchTerm', function(){
            vm.places = [];
            if (vm.searchTerm.length>=3) {
                filtrar();
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('imageUrl',"").withOption("name", "imageUrl").withOption('width', '5%').notSortable().renderWith(function(data, type, full) {
                if (data) return "<img ng-click='vm.openUser($event, \"" + data + "\")'  style='border-radius:50%;height:40px;width:40px;' src='" + data + "' />";
                else return "<img style='border-radius:50%;height:40px;width:40px;' src='https://static.vipcard.group/profile/no_avatar.jpg' />";
            }),
            DTColumnBuilder.newColumn('lastName',$translate('vipWebApp.customer.lastName')).withOption("name", "lastName").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('firstName',$translate('vipWebApp.customer.firstName')).withOption("name", "firstName").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('email',$translate('vipWebApp.customer.email')).withOption("name", "email").notSortable().renderWith(function(data, type, full) {
                return "<a href='mailto:" + data + "'>" + data + "</a>"
            }),
            DTColumnBuilder.newColumn('yearsOld', $translate('vipWebApp.customer.yearsOld')).withOption("name", "yearsOld").renderWith(function(data, type, full) {
                return data !== undefined ? data : ""
            }),
            DTColumnBuilder.newColumn('gender',$translate('vipWebApp.customer.gender')).withOption("name", "gender").notSortable().withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter('translate')('vipWebApp.customer.genders.' + data)
            }),
            DTColumnBuilder.newColumn('postalCode',$translate('vipWebApp.customer.postalCode')).withOption("name", "postalCode").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('country',$translate('vipWebApp.customer.country')).withOption("name", "country").notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('codes',$translate('vipWebApp.customer.code')).withOption("name", "codes").notSortable().withOption('defaultContent', '').renderWith(function(data, type, full) {
                return data[0];
            }),
            DTColumnBuilder.newColumn('cardNames',$translate('vipWebApp.customer.cardName')).withOption("name", "cardNames").notSortable().withOption('defaultContent', '').renderWith(function(data, type, full) {
                var vips = data[0];
                if (data !== undefined) {
                    vips = vips + " <md-button class='md-button md-icon-button' ng-click='vm.showMore($event," + full.id + ")'><md-icon md-font-icon='icon-eye'></md-icon></md-button>";
                }
                return vips;
            }),
            DTColumnBuilder.newColumn('id',"").withOption("width", "42px").notSortable().withOption('defaultContent', '').renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/user-apps/business-places/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                    if (jqXHR.responseJSON.totalActive != null) {
                        vm.totalActive = jqXHR.responseJSON.totalActive;
                        vm.totalPending = jqXHR.responseJSON.totalPending;
                        vm.places = [
                            {id: '', name: 'Todos'},
                            ...jqXHR.responseJSON.auxiliar
                        ];
                    }
                    $scope.loadingProgress = false;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[1,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function actionsHtml(data, type, full, meta){
            var html = '<button type="submit" ng-click="vm.deleteConfirm($event, ' + full.cardIds[0] + ', ' + full.placeIds[0] + ', \'' + full.codes[0] + '\')" class="md-button edit-button md-icon-button">' +
                '<i class="md-pinkTheme-theme md-font material-icons icon-trash red-500-fg" tooltip="Delete"></i></button>';
            return html;
        }

        function exportData() {
            const filter = {
                search: vm.searchTerm,
                status: vm.status,
                vipFilter: vm.vipFilter,
                dateFrom: DateUtils.convertLocalDateTimeToServer(vm.dateStart)
            };
            UserAppService.exportToExcel(filter).then(function(response){
                var header = response.headers('Content-Disposition');
                var fileName = header.split("=")[2].replace(/\"/gi,'');

                var blob = new Blob([response.data], {type : 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'});
                var objectUrl = (window.URL || window.webkitURL).createObjectURL(blob);
                var link = angular.element('<a/>');
                link.attr({
                    href : objectUrl,
                    download : fileName
                })[0].click();
            });
        }

        /**
         * Confirm Dialog
         */
        function deleteEntityConfirm(ev, cardId, placeId, code)
        {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('vipWebApp.customer.delete.title'))
                .htmlContent($translate.instant('vipWebApp.customer.delete.text', {code: code}))
                .ariaLabel('Delete Customer')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                UserAppService.deleteContact(placeId, cardId).then(function(data) {
                    vm.dtInstance.reloadData();
                });
            }, function () {

            });
        }

        function showMore(ev, cardId)
        {
            // Buscamos el cardId en la lista de resultados
            this.dtInstance.DataTable.data().each(function(d) {
                if (d.id == cardId) {

                    $mdDialog.show({
                        controllerAs       : 'vm',
                        templateUrl        : 'app/main/customers/codes-dialog.html',
                        parent             : angular.element($document.find('#content-container')),
                        targetEvent        : ev,
                        clickOutsideToClose: true,
                        locals             : {
                            card: d
                        },
                        controller: CodeDetailController
                    });
                }
            });
        }

        function openUser(ev, url) {
            $mdDialog.show({
                controllerAs       : 'vm',
                templateUrl        : 'app/main/request/pic-dialog.html',
                parent             : angular.element($document.find('#content-container')),
                targetEvent        : ev,
                clickOutsideToClose: true,
                locals             : {
                    url: url
                },
                controller: PicDialogController
            });
        }

        PicDialogController.$inject = ['$scope', '$mdDialog', 'url'];
        function PicDialogController($scope, $mdDialog, url) {
            $scope.url = url;
        }

        CodeDetailController.$inject = ['$scope', '$mdDialog', 'card'];
        function CodeDetailController($scope, $mdDialog, card) {
            $scope.card = card;

            $scope.closeDialog = closeDialog;
            $scope.deleteConfirm = deleteEntityConfirm;
            $scope.activatedCheck = activated;

            $scope.range = function() {
                var input = [];
                for (var i = 0; i < $scope.card.codes.length; i++) {
                    if ($scope.card.enableds[i] != null) input.push(i);
                }
                return input;
            };

            function closeDialog()
            {
                $mdDialog.hide();
            }

            function activated(card, n) {
                let today = new Date();
                if (card.activations[n] && card.lastActivation[n] != null) {
                    if (card.lastActivation[n] > today)
                        return '<i class="md-pinkTheme-theme md-font material-icons icon-check green-500-fg"></i>';
                    else
                        return '<i class="md-pinkTheme-theme md-font material-icons icon-close red-500-fg"></i>';
                } else return '<i class="md-pinkTheme-theme md-font material-icons icon-minus grey-500-fg"></i>';
            }

            /**
             * Confirm Dialog
             */
            function deleteEntityConfirm(ev, cardId, placeId, code)
            {
                var confirm = $mdDialog.confirm()
                    .title($translate.instant('vipWebApp.customer.delete.title'))
                    .htmlContent($translate.instant('vipWebApp.customer.delete.text', {code: code}))
                    .ariaLabel('Delete Customer')
                    .targetEvent(ev)
                    .ok('OK').cancel('CANCEL');

                $mdDialog.show(confirm).then(function () {
                    UserAppService.deleteContact(placeId, cardId).then(function(data) {
                        vm.dtInstance.reloadData();
                    })
                }, function () {

                });
            }
        }

    }
})();
