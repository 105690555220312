(function ()
{
    'use strict';

    angular
        .module('vipWebApp')
        .config(config);

    config.$inject = ['$stateProvider', '$translatePartialLoaderProvider'];

    /** @ngInject */
    function config($stateProvider, $translatePartialLoaderProvider)
    {
        // State
        $stateProvider.state('login', {
            url    : '/',
            parent : 'app',
            data     : {
                title: 'Login'
            },
            views    : {
                'main@': {
                    templateUrl: 'app/core/layouts/content-only.html',
                    controller : 'MainController as vm'
                },
                'content@login': {
                    templateUrl: 'app/account/login/login.html',
                    controller : 'LoginWebController as vm'
                }
            },
            bodyClass: 'login',
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ]
            }
        });

        // Translation
        $translatePartialLoaderProvider.addPart('login');
        $translatePartialLoaderProvider.addPart('global');

    }

})();
