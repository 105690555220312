(function ()
{
    'use strict';

    BusinessPlacesCodesController.$inject = ["$state", '$scope', "paginationConstants", "Currencies", 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate'];
    angular
        .module('app.businesses')
        .controller('BusinessPlacesCodesController', BusinessPlacesCodesController);

    /** @ngInject */
    function BusinessPlacesCodesController($state, $scope, paginationConstants, Currencies, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate)
    {
        var vm = this;
        vm.page = 1;
        vm.totalItems = null;
        vm.links = null;
        vm.searchTerm="";
        vm.itemsPerPage = paginationConstants.itemsPerPage;

        // Data
        vm.dtInstance = {};
        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id").withOption("width", "72px"),
            DTColumnBuilder.newColumn('logoCard','').withOption('defaultContent', '').withOption("width", "80px").renderWith(logoHtml),
            DTColumnBuilder.newColumn('name',$translate('BP.BUSINESS_NAME')).withOption("name", "nameEs").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('cardName',$translate('BP.CARD_NAME')).withOption("name", "nameFr").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('address',$translate('BP.ADDRESS')).withOption("name", "address").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('city',$translate('BP.CITY')).withOption("name", "city").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '250px').notSortable().renderWith(actionsHtml)
        ];

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/business-places/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(vm.itemsPerPage)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function logoHtml(data, type, full, meta) {
            return '<img class="business-image" src="' + data + '">';
        }

        function priceHtml(data, type, full, meta) {
            return GUIUtils.formatCurrency(data, 3701, Currencies);
        }

        function actionsHtml(data, type, full, meta){
            var html = '<button class="md-raised md-button md-default-theme md-ink-ripple" ng-click="vm.gotoBusinessDetail(' + data.id + ')" translate translate-attr-aria-label="BP.BUSINESS_DETAILS">' +
                       'GENERAR CÓDIGOS' +
                       '</button>';
            return html;
        }


        vm.gotoBusinessDetail = gotoBusinessDetail;

        /**
         * Go to business detail
         *
         * @param id
         */
        function gotoBusinessDetail(id)
        {
            $state.go('app.businesses.businessCode.detailCode', {id: id});
        }
    }
})();
