(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('BusinessDialogController', BusinessDialogController);

    BusinessDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'Business'];

    function BusinessDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, Business) {
        var vm = this;

        vm.taToolbar = [
            ['quote', 'bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'redo', 'undo', 'clear', 'html'],
            ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull', 'indent', 'outdent']
        ];

        vm.business = entity;
        vm.clear = clear;
        vm.save = save;

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.business.id !== null) {
                Business.update(vm.business, onSaveSuccess, onSaveError);
            } else {
                Business.save(vm.business, onSaveSuccess, onSaveError);
            }
        }

    }
})();
