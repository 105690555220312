(function ()
{
    'use strict';

    OrdersController.$inject = ['$state', 'Order', 'OrderStatuses', 'Currencies', 'SelectedBusiness', '$scope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', '$compile', '$translate', 'GUIUtils'];

    angular
        .module('app.e-commerce')
        .controller('OrdersController', OrdersController);

    /** @ngInject */
    function OrdersController($state, Order, OrderStatuses, Currencies, SelectedBusiness, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, $compile, $translate, GUIUtils)
    {
        var vm = this;

        // Data
        vm.dtInstance = {};
        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id"),
            DTColumnBuilder.newColumn('orderNumber',$translate('vipWebApp.order.orderNumber')).withOption("name", "orderNumber").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('userName',$translate('vipWebApp.order.user')).withOption("name", "user.user.firstName").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('orderTotal',$translate('vipWebApp.order.orderTotal')).notSortable().withOption('defaultContent', '').renderWith(priceHtml),
            DTColumnBuilder.newColumn('totalItems',$translate('vipWebApp.order.items')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('orderStatus',$translate('vipWebApp.order.orderStatus')).withOption("name", "orderStatus").withOption('defaultContent', '').renderWith(statusHtml),,
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', getAjaxForm())
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function priceHtml(data, type, full, meta) {
            return GUIUtils.formatCurrency(data, full.currencyId, Currencies);
        }

        function statusHtml(data, type, full, meta){
            // Show last order status
            var orderStatus = OrderStatuses[data];
            if ( type === 'display' ) {
                return '<span class="status ' + orderStatus.color + '" data-translate="' + orderStatus.name + '">' + orderStatus.name + ' </span>';
            }
            if ( type === 'filter' ) {
                return orderStatus.name;
            }
            return data;
        }

        function actionsHtml(data, type, full, meta){
            var html = '<md-button class="edit-button md-icon-button" ng-click="vm.gotoOrderDetail(\'' + data.id + '\')" aria-label="Order details" ' +
                            'translate translate-attr-aria-label="EC.ORDER_DETAILS">' +
                            '<md-icon md-font-icon="icon-dots-horizontal" class="s16"></md-icon>' +
                       '</md-button>';
            return html;
        }


        $scope.$on('businessChanged', function() {
            vm.dtOptions.withOption('ajax', getAjaxForm());
            vm.dtInstance.rerender();
        });


        // Methods
        vm.gotoOrderDetail = gotoOrderDetail;

        function getAjaxForm() {
            return {
                url: "/api/orders/dt",
                data: {
                    businessId: SelectedBusiness.getBusiness().id
                },
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            };
        }

        /**
         * Go to product detail
         *
         * @param id
         */
        function gotoOrderDetail(id)
        {
            $state.go('app.e-commerce.orders.detail', {id: id});
        }
    }
})();
