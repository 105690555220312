(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .filter('findLanguageFromKey', findLanguageFromKey)
        .filter('findLanguageRtlFromKey', findLanguageRtlFromKey);

    var languages = {
        //'ca': { name: 'Català', code: "ca", flag: "ca" },
        'en': { name: 'English', code: "en", flag: "gb" },
        'es': { name: 'Español', code: "es", flag: "es" },
        'fr': { name: 'Français', code: "fr", flag: "fr" }
        // jhipster-needle-i18n-language-key-pipe - JHipster will add/remove languages in this object
    };

    function findLanguageFromKey() {
        return findLanguageFromKeyFilter;

        function findLanguageFromKeyFilter(lang) {
            return languages[lang].name;
        }
    }

    function findLanguageRtlFromKey() {
        return findLanguageRtlFromKeyFilter;

        function findLanguageRtlFromKeyFilter(lang) {
            return languages[lang].rtl;
        }
    }

})();
