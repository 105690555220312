(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .config(stateConfig)
        .config(pluginsConfig);

    stateConfig.$inject = ['$stateProvider'];
    pluginsConfig.$inject = ['$mdDateLocaleProvider'];

    function stateConfig($stateProvider) {

        var layout = {
            main      : 'app/core/layouts/vertical-navigation.html',
            toolbar   : 'app/toolbar/layouts/vertical-navigation/toolbar.html',
            navigation: 'app/navigation/layouts/vertical-navigation/navigation.html'
        };

        $stateProvider.state('app', {
            abstract: true,
            authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS', 'ROLE_QR_CHECK'],
            views: {
                'main@'         : {
                    templateUrl: layout.main,
                    controller : 'HomeController',
                    controllerAs: 'vm'
                },
                'toolbar@app'   : {
                    templateUrl: layout.toolbar,
                    controller : 'ToolbarController',
                    controllerAs: 'vm'
                },
                'navigation@app': {
                    templateUrl: layout.navigation,
                    controller : 'NavigationController',
                    controllerAs: 'vm'
                }
                /*'quickPanel@app': {
                    templateUrl: 'app/quick-panel/quick-panel.html',
                    controller : 'QuickPanelController',
                    controllerAs: 'vm'
                },
                'navbar@': {
                    templateUrl: 'app/layouts/navbar/navbar.html',
                    controller: 'NavbarController',
                    controllerAs: 'vm'
                }*/
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ],
                translatePartialLoader: ['$translate', '$translatePartialLoader', function ($translate, $translatePartialLoader) {
                    $translatePartialLoader.addPart('global');
                }]
            }
        });
    }

    function pluginsConfig($mdDateLocaleProvider) {
        $mdDateLocaleProvider.formatDate = function(date) {
            return  date ? moment(date).format('DD/MM/YYYY') : '';
        };
    }
})();
