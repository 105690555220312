(function ()
{
    'use strict';

    ToolbarController.$inject = ["$rootScope", "$q", "$state", "$timeout", "$mdSidenav", "$translate", "Auth", "msNavigationService", "JhiLanguageService", "tmhDynamicLocale", "Principal", "BusinessPlaceService", "BusinessPlaceUtils", "SelectedBusiness"];
    angular
        .module('app.toolbar')
        .controller('ToolbarController', ToolbarController);

    /** @ngInject */
    function ToolbarController($rootScope, $q, $state, $timeout, $mdSidenav, $translate, Auth, msNavigationService, JhiLanguageService, tmhDynamicLocale, Principal, BusinessPlaceService, BusinessPlaceUtils, SelectedBusiness)
    {
        var vm = this;

        // Data
        $rootScope.global = {
            search: ''
        };

        vm.bodyEl = angular.element('body');

        vm.languages = null;
        vm.selectedLanguage = null;
        vm.selectedBusiness = null;

        // Methods
        vm.toggleSidenav = toggleSidenav;
        vm.logout = logout;
        vm.changeLanguage = changeLanguage;
        vm.changeBusiness = changeBusiness;
        vm.toggleHorizontalMobileMenu = toggleHorizontalMobileMenu;
        vm.toggleMsNavigationFolded = toggleMsNavigationFolded;
        vm.search = search;
        vm.searchResultClick = searchResultClick;

        //////////

        init();

        /**
         * Initialize
         */
        function init()
        {
            JhiLanguageService.getAllToolbar().then(function (languages) {
                vm.languages = languages;
                changeLanguage(vm.languages[$translate.preferredLanguage()]);
            });

            BusinessPlaceService.query({
                page: 0,
                size: 20,
                sort: ['id,asc']
            }, function(data) {
                vm.businesses = data;
                if (data!=null&&data.length>0) changeBusiness(data[0]);
            });

            Principal.identity().then(function(account) {
                vm.account = account;
            });

            reloadMenuOptions();
        }

        $rootScope.$on('menuChanged', function() {
            reloadMenuOptions();
        });

        function reloadMenuOptions() {
            BusinessPlaceUtils.getMenuTotals().then(function (menuTotals) {

                if (menuTotals.offers > 0) {
                    msNavigationService.saveItem('apps.businessOffers', {
                        icon: 'icon-gift',
                        title: 'global.menu.vc.businessOffer',
                        state: 'app.businesses.offers',
                        weight: 8,
                        badge: { content: menuTotals.offers, color  : 'green' },
                        authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
                    });
                }

                if (menuTotals.discounts > 0) {
                    msNavigationService.saveItem('apps.businessDiscounts', {
                        icon: 'icon-percent',
                        title: 'global.menu.vc.businessDiscount',
                        state: 'app.businesses.discounts',
                        weight: 9,
                        badge: { content: menuTotals.discounts, color  : 'green' },
                        authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS']
                    });
                }

                if (menuTotals.requests > 0) {
                    msNavigationService.saveItem('apps.businessesRequest', {
                        icon  : 'icon-table',
                        title: 'global.menu.vc.businessesRequest',
                        state: 'businessesRequest',
                        badge: { content: menuTotals.requests, color  : 'red' },
                        authorities: ['ROLE_ADMIN', 'ROLE_BUSINESS'],
                        weight: 15
                    });
                }
            });
        }

        /**
         * Toggle sidenav
         *
         * @param sidenavId
         */
        function toggleSidenav(sidenavId)
        {
            $mdSidenav(sidenavId).toggle();
        }

        /**
         * Logout Function
         */
        function logout() {
            Auth.logout();
            $state.go('login');
        }

        /**
         * Change Language
         */
        function changeLanguage(lang)
        {
            vm.selectedLanguage = lang;
            $translate.use(lang.code);
            tmhDynamicLocale.set(lang.code);
        }

        /**
         * Change Business
         */
        function changeBusiness(business)
        {
            vm.selectedBusiness = business;
            SelectedBusiness.setBusiness(business);
            $rootScope.$broadcast("businessChanged");
        }

        /**
         * Toggle horizontal mobile menu
         */
        function toggleHorizontalMobileMenu()
        {
            vm.bodyEl.toggleClass('ms-navigation-horizontal-mobile-menu-active');
        }

        /**
         * Toggle msNavigation folded
         */
        function toggleMsNavigationFolded()
        {
            msNavigationService.toggleFolded();
        }

        /**
         * Search action
         *
         * @param query
         * @returns {Promise}
         */
        function search(query)
        {
            var navigation = [],
                flatNavigation = msNavigationService.getFlatNavigation(),
                deferred = $q.defer();

            // Iterate through the navigation array and
            // make sure it doesn't have any groups or
            // none ui-sref items
            for ( var x = 0; x < flatNavigation.length; x++ )
            {
                if ( flatNavigation[x].uisref )
                {
                    navigation.push(flatNavigation[x]);
                }
            }

            // If there is a query, filter the navigation;
            // otherwise we will return the entire navigation
            // list. Not exactly a good thing to do but it's
            // for demo purposes.
            if ( query )
            {
                navigation = navigation.filter(function (item)
                {
                    if ( angular.lowercase(item.title).search(angular.lowercase(query)) > -1 )
                    {
                        return true;
                    }
                });
            }

            // Fake service delay
            $timeout(function ()
            {
                deferred.resolve(navigation);
            }, 1000);

            return deferred.promise;
        }

        /**
         * Search result click action
         *
         * @param item
         */
        function searchResultClick(item)
        {
            // If item has a link
            if ( item.uisref )
            {
                // If there are state params,
                // use them...
                if ( item.stateParams )
                {
                    $state.go(item.state, item.stateParams);
                }
                else
                {
                    $state.go(item.state);
                }
            }
        }
    }

})();
