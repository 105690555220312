(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('VisitSubscriptionController', VisitSubscriptionController);

    VisitSubscriptionController.$inject = ['$state', '$stateParams', '$scope', 'VisitService', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', 'DateUtils', '$translate', '$interval'];

    function VisitSubscriptionController($state, $stateParams, $scope, VisitService, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, DateUtils, $translate, $interval) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};
        vm.total = 0;

        vm.title = $translate.instant('vipWebApp.discount.home.title');

        var discountId = 1;

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });


        VisitService.getVisitInfo($stateParams.id).then(function(data) {
            vm.title = data.name;
            //vm.totalExpense = data.totalExpense;
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('fullName',$translate('vipWebApp.discount.orders.fullName')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('email',$translate('vipWebApp.discount.orders.email')).notSortable().withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('eventDate',$translate('vipWebApp.discount.orders.subscribedDate')).withOption("name", "eventDate").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm");
            })
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/visit/" + $stateParams.id + "/subscription/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[2,'desc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');



    }
})();
