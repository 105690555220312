(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('ProductTaxDialogController', ProductTaxDialogController);

    ProductTaxDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$mdDialog', 'entity', 'ProductTax', 'Country'];

    function ProductTaxDialogController ($timeout, $scope, $stateParams, $mdDialog, entity, ProductTax, Country) {
        var vm = this;

        vm.productTax = entity;
        vm.clear = clear;
        vm.save = save;
        vm.countries = Country.query();

        vm.queryCountries   = queryCountries;
        vm.selectedCountryChange = selectedCountryChange;

        function clear () {
            closeDialog();
        }

        function onSaveSuccess (result) {
            vm.isSaving = false;
            closeDialog(result);
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        function closeDialog()
        {
            $mdDialog.hide();
        }

        function save () {
            vm.isSaving = true;
            if (vm.productTax.id !== null) {
                ProductTax.update(vm.productTax, onSaveSuccess, onSaveError);
            } else {
                ProductTax.save(vm.productTax, onSaveSuccess, onSaveError);
            }
        }

        function selectedCountryChange(item) {
            vm.productTax.countryId = item.id;
        }

        function queryCountries (query) {
            var results = query ? vm.countries.filter( createFilterFor(query) ) : vm.countries;
            return results;
        }

        function createFilterFor(query) {
            var lowercaseQuery = query.toLowerCase();
            return function filterFn(country) {
                return (country.nameEs.toLowerCase().indexOf(lowercaseQuery) === 0);
            };
        }

    }
})();
