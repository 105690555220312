(function ()
{
    'use strict';

    selectedBusiness.$inject = [];
    angular
        .module('app.businesses')
        .factory('SelectedBusiness', selectedBusiness);

    /** @ngInject */
    function selectedBusiness()
    {
        var business;

        var service = {
            setBusiness: setBusiness,
            getBusiness: getBusiness
        };

        function setBusiness(business) {
            this.business = business;
        }

        function getBusiness() {
            return this.business;
        }

        return service;
    }

})();
