(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('UserManagementController', UserManagementController);

    UserManagementController.$inject = ['User', '$state', '$scope', 'DTColumnBuilder', 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate', 'DateUtils', '$mdDialog'];

    function UserManagementController(User, $state, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate, DateUtils, $mdDialog) {
        var vm = this;

        vm.authorities = ['ROLE_USER', 'ROLE_ADMIN', 'ROLE_SUPERADMIN', 'ROLE_BUSINESS', 'ROLE_BUSINESS_ADMIN', 'ROLE_BUSINESS_VISITS', 'ROLE_PROMOTOR', 'ROLE_QR_READER', 'ROLE_QR_CHECK', 'ROLE_QR_DAR_VIP',  'ROLE_QR_BONUS', 'ROLE_QR_BAR', 'ROLE_BUSINESS_REQUEST'];
        vm.currentAccount = null;
        vm.languages = null;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};

        vm.addNew = addNewDialog;
        vm.edit = editEntityDialog;
        vm.deleteEntity = deleteEntity;
        vm.deleteConfirm = deleteEntityConfirm;

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id"),
            DTColumnBuilder.newColumn('login',$translate('userManagement.login')).withOption("name", "login").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('email',$translate('userManagement.email')).withOption("name", "email").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('langKey',$translate('userManagement.langKey')).withOption("name", "langKey").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('lastModifiedBy',$translate('userManagement.lastModifiedBy')).withOption("name", "lastModifiedBy").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('lastModifiedDate',$translate('userManagement.lastModifiedDate')).withOption("name", "lastModifiedDate").withOption('defaultContent', '').renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy HH:mm")
            }),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/users/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate("'" + data.login + "'", ['all']);
        }

        /**
         * Open new contact dialog
         *
         * @param ev
         * @param entity
         */
        function editEntityDialog(ev, login)
        {
            $mdDialog.show({
                templateUrl   : 'app/admin/user-management/user-management-dialog-material.html',
                controller    : 'UserManagementDialogController',
                controllerAs  : 'vm',
                backdrop      : 'static',
                targetEvent   : ev,
                resolve: {
                    entity: ['User', function(User) {
                        return User.get({login : login});
                    }]
                }
            }).then(function() {
                loadAll();
            });
        }

        /**
         * Open new dialog to create entity
         *
         */
        function addNewDialog()
        {
            $mdDialog.show({
                templateUrl: 'app/admin/user-management/user-management-dialog-material.html',
                controller: 'UserManagementDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            id: null, login: null, firstName: null, lastName: null, email: null,
                            activated: true, langKey: null, createdBy: null, createdDate: null,
                            lastModifiedBy: null, lastModifiedDate: null, resetDate: null,
                            resetKey: null, authorities: null
                        };
                    }
                }
            }).then(function() {
                loadAll();
            });
        }

        /**
         * Confirm Dialog
         */
        function deleteEntityConfirm(ev, login)
        {
            var confirm = $mdDialog.confirm()
                .title(translate('global.delete.confirmation.title'))
                .htmlContent(translate('global.delete.confirmation.content', {param: user.login}))
                .ariaLabel('Delete User')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                deleteEntity(login);
            }, function () {

            });
        }

        /**
         * Delete Entity
         */
        function deleteEntity(login)
        {
            User.delete({login: login}, function () {
                 // do nothing
            });

        }
    }
})();
