(function() {
    'use strict';

    angular
        .module('vipWebApp')
        .controller('ApplicationController', ApplicationController);

    ApplicationController.$inject = ['$state', 'Application', '$scope', 'DTColumnBuilder' , 'DTOptionsBuilder', 'DTHeader', 'GUIUtils', '$filter', '$compile', '$translate', '$mdDialog', 'DateUtils'];

    function ApplicationController ($state, Application, $scope, DTColumnBuilder , DTOptionsBuilder, DTHeader, GUIUtils, $filter, $compile, $translate, $mdDialog, DateUtils) {

        var vm = this;

        vm.searchTerm = "";
        vm.searched=false;
        vm.dtInstance = {};

        vm.addNew = addNewDialog;
        vm.edit = editEntityDialog;
        vm.deleteEntity = deleteEntity;
        vm.deleteConfirm = deleteEntityConfirm;

        vm.title = $translate.instant('vipWebApp.application.home.title');
        vm.newButtonTitle = $translate.instant('vipWebApp.application.home.createLabel');

        $scope.$watch('vm.searchTerm', function(){
            if (vm.searchTerm.length>=3) {
                vm.dtInstance.DataTable.search(vm.searchTerm);
                vm.dtInstance.reloadData();
                vm.searched=true;
            } else if (vm.searched) {
                vm.dtInstance.DataTable.search("");
                vm.dtInstance.reloadData();
                vm.searched=false;
            }
        });

        vm.dtColumns = [
            DTColumnBuilder.newColumn('id',$translate('global.field.id')).withOption("name", "id"),
            DTColumnBuilder.newColumn('name',$translate('vipWebApp.application.name')).withOption("name", "name").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('description',$translate('vipWebApp.application.description')).withOption("name", "description").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('uuid',$translate('vipWebApp.application.uuid')).withOption("name", "uuid").withOption('defaultContent', ''),
            DTColumnBuilder.newColumn('creationDate',$translate('vipWebApp.application.creationDate')).withOption("name", "creationDate").withOption('defaultContent', '').notSortable().renderWith(function(data, type, full) {
                return $filter("date")(DateUtils.convertDateTimeFromServer(data), "dd/MM/yyyy")
            }),
            DTColumnBuilder.newColumn(null).withTitle($translate('entity.action.actions')).withOption('width', '155px').notSortable().renderWith(actionsHtml)
        ];

        vm.dtOptions = DTOptionsBuilder.newOptions()
            .withOption('ajax', {
                url: "/api/applications/dt",
                dataSrc: "data", type:"POST",
                headers: DTHeader.request(),
                complete: function(jqXHR, textStatus) {
                    vm.totalEntities = jqXHR.responseJSON.recordsTotal;
                }
            })
            .withOption('createdRow', createdRow)
            .withLanguage(GUIUtils.getLanguageDT($translate.use()))
            .withOption('processing', true)
            .withOption('serverSide', true)
            .withPaginationType('simple_numbers')
            .withDisplayLength(20)
            .withOption('scrollY', 'auto')
            .withOption('responsive', true)
            .withOption('aaSorting',[0,'asc'])
            .withDOM('rt<"bottom"<"left"<"length"l>><"right"<"info"i><"pagination"p>>>');

        function createdRow(row, data, dataIndex) {
            $compile(angular.element(row).contents())($scope);
        }

        function actionsHtml(data, type, full, meta){
            return GUIUtils.getActionsTemplate("'" + data.id + "'", ['all']);
        }

        /**
         * Open new contact dialog
         *
         * @param ev
         * @param entity
         */
        function editEntityDialog(ev, data)
        {
            $mdDialog.show({
                templateUrl: 'app/entities/application/application-dialog.html',
                controller: 'ApplicationDialogController',
                controllerAs: 'vm',
                backdrop      : 'static',
                targetEvent   : ev,
                resolve: {
                    entity: ['Application', function(Application) {
                        return Application.get({id : data}).$promise;
                    }]
                }
            }).then(function() {
                vm.dtInstance.reloadData();
            });
        }

        /**
         * Open new dialog to create entity
         *
         */
        function addNewDialog()
        {
            $mdDialog.show({
                templateUrl: 'app/entities/application/application-dialog.html',
                controller: 'ApplicationDialogController',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    entity: function () {
                        return {
                            name: null,
                            description: null,
                            uuid: null,
                            creationDate: null,
                            deletionDate: null,
                            id: null
                        };
                    }
                }
            }).then(function() {
                vm.dtInstance.reloadData();
            });
        }

        /**
         * Confirm Dialog
         */
        function deleteEntityConfirm(ev, data)
        {
            var confirm = $mdDialog.confirm()
                .title($translate.instant('global.delete.confirmation.title'))
                .htmlContent($translate.instant('global.delete.confirmation.content', {param: data}))
                .ariaLabel('Delete Application')
                .targetEvent(ev)
                .ok('OK').cancel('CANCEL');

            $mdDialog.show(confirm).then(function () {
                deleteEntity(data);
            }, function () {

            });
        }

        /**
         * Delete Entity
         */
        function deleteEntity(data)
        {
            Application.delete({id: data},
                function () {
                    vm.dtInstance.reloadData();
                }
            );

        }
    }
})();
